import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Dialog, DialogContent, DialogHeader, DialogFooter, Button, Loader, Avatar, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESIGNING_PL_FILES_ATTACHMENT_SUPPORTED_MIME_TYPES } from 'app/domains/esigning/utils';
import { FileBrowser } from 'app/domains/filesystem';
import { DocumentType, EntryKind } from 'shared/domains/apollo/generated/types';
import { useActiveDocument, ACTIVE_STATUSES } from 'shared/domains/documents';
import { MimeTypesByExtension } from 'shared/domains/filesystem/utils/fileExtensions';
import { ConversionAlert } from './ConversionAlert';
export function AttachmentBrowser(props) {
    const { id } = useActiveDocument();
    const { onAdd, onCancel, loading } = props;
    const [selected, setSelected] = useState();
    const handleSelectDocument = (entry) => setSelected(entry);
    const handleOnAdd = () => {
        if (!selected) {
            return;
        }
        onAdd(selected);
    };
    const selectable = (entry) => {
        if (entry.id === id) {
            return false;
        }
        if (entry.kind !== EntryKind.Document) {
            return false;
        }
        if (entry.type === DocumentType.Uploaded) {
            return ESIGNING_PL_FILES_ATTACHMENT_SUPPORTED_MIME_TYPES.includes(entry.mimeType);
        }
        return true;
    };
    const isSentForEsigning = Boolean((selected === null || selected === void 0 ? void 0 : selected.status) && ACTIVE_STATUSES.includes(selected.status));
    const isPDF = isSentForEsigning ||
        ((selected === null || selected === void 0 ? void 0 : selected.type) === DocumentType.Uploaded && (selected === null || selected === void 0 ? void 0 : selected.mimeType) === MimeTypesByExtension.Pdf);
    return (<Dialog onClose={onCancel}>
      <DialogHeader title={<Trans comment="E-signing attachment browser title">Select the file to attach</Trans>} media={<Avatar shape="square">
            <Falcon icon="file-circle-plus"/>
          </Avatar>}/>
      <DialogContent>
        {selected && !isPDF && <ConversionAlert loading={loading}/>}
        <FileBrowser disabled={loading} selfId={id} onSelect={handleSelectDocument} selectable={selectable} showSignRequestStatus/>
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={onCancel} disabled={loading}>
          <Trans comment="Cancel button label in the E-signing attachment browser">Cancel</Trans>
        </Button>
        <Button appearance="primary" onClick={handleOnAdd} disabled={!selected || loading}>
          <Trans comment="Submit button label in the E-signing attachment browser">Add</Trans>
          {loading && <Loader />}
        </Button>
      </DialogFooter>
    </Dialog>);
}
