import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { searchFilterCopy, useSearchContext, } from 'app/domains/global-search';
import { MenuMultiSelectItem, SearchableMenu } from 'shared/domains/common-ui';
export function AddFilterButton(props) {
    const { isFilterActive, addFilterToDraft } = useSearchContext();
    const { filterKeys } = props;
    const filterItems = filterKeys
        .map(item => ({
        id: item,
        paramKey: item,
        label: searchFilterCopy()[item],
    }))
        .sort((a, b) => {
        if (a.label.toLowerCase() > b.label.toLowerCase())
            return 1;
        if (a.label.toLowerCase() < b.label.toLowerCase())
            return -1;
        return 0;
    });
    if (filterItems.length === 0)
        return null;
    return (<SearchableMenu items={filterItems} keys={['label']} renderTarget={({ setOpen, open }) => (<Button type="button" size="small" appearance="secondary" onClick={() => setOpen(!open)}>
          <Falcon icon="plus"/>
          <Trans comment="Search: Add filter button">Add filter</Trans>
        </Button>)}>
      {({ items, setOpen }) => items.map(item => (<MenuMultiSelectItem key={item.id} selected={isFilterActive(item.paramKey)} onClick={() => {
                addFilterToDraft(item.paramKey);
                setOpen(false);
            }}>
            {item.label}
          </MenuMultiSelectItem>))}
    </SearchableMenu>);
}
