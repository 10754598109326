import { i18n } from '@lingui/core';
import { TableHead, TableRow, TableHeaderText, TableHeadCell, Box, Checkbox, } from '@pocketlaw/tetris';
import { useTableContext } from './TableContext';
import { TITLE_CELL_WIDTH } from './constants';
import { useSearchContext } from '../SearchProvider';
export function SearchResultsTableHead() {
    const { columnConfigs } = useSearchContext();
    const { results, selected, setSelected, actions } = useTableContext();
    const columns = columnConfigs.filter(column => column.label && column.id);
    const allSelected = selected.length === results.length;
    const handleToggleSelectAll = () => {
        if (allSelected) {
            setSelected({ type: 'reset' });
        }
        else {
            setSelected({ type: 'select-all' });
        }
    };
    const getSortId = (column) => {
        if (column.sortable) {
            return column.id;
        }
        return undefined;
    };
    const getStickyColumn = (column) => {
        if (column.id === 'action') {
            return 'right';
        }
        return undefined;
    };
    return (<TableHead>
      <TableRow>
        {columns.map(column => (<TableHeadCell key={column.id} id={column.id} maxWidth={column.id === 'name' ? TITLE_CELL_WIDTH : undefined} colSpan={column.colSpan} stickyColumn={getStickyColumn(column)}>
            <Box alignItems="center" gap="md">
              {column.id === 'name' && actions && (<Box pl="lg">
                  <Checkbox onChange={handleToggleSelectAll} checked={allSelected}/>
                </Box>)}
              {column.id !== 'action' && (<TableHeaderText sortId={getSortId(column)}>{i18n._(column.label)}</TableHeaderText>)}
            </Box>
          </TableHeadCell>))}
      </TableRow>
    </TableHead>);
}
