import { DialogContent, Table, TableRow, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const StyledDialogContent = styled(DialogContent) `
  padding: ${themeGet('spacing.2xl')};
  border-top: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  border-bottom: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
`;
export const StyledTable = styled(Table) `
  table-layout: fixed;
`;
export const StyledTableRow = styled(TableRow) `
  ${props => props.$disabled && 'opacity: 0.5;'};
  ${props => props.$hoverCursor && 'cursor: pointer;'};
`;
export const StyledButton = styled.button `
  display: flex;
  cursor: inherit;
`;
