import { Typography, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
function transitionStates(state) {
    const hidden = css `
    opacity: 0;
    transform: translate(0, ${pxToRem(-10)});
  `;
    const visible = css `
    opacity: 1;
    transform: translate(0, 0);
  `;
    const leaving = css `
    opacity: 0;
    transform: translate(0, ${pxToRem(10)});
  `;
    switch (state) {
        case 'hidden':
            return hidden;
        case 'leaving':
            return leaving;
        case 'visible':
        default:
            return visible;
    }
}
const DIGIT_HEIGHT = '2rem';
export const AnimatedList = styled(Typography).attrs(() => ({
    $fontSize: 'huge',
    $fontWeight: 'bold',
    $appearance: '300',
    $lineHeight: 'neutralized',
})) `
  position: relative;
  height: ${DIGIT_HEIGHT};
  overflow: hidden;
  margin-bottom: ${themeGet('spacing.sm')};
`;
export const AnimatedContainer = styled.div `
  display: flex;
  align-items: center;
  position: absolute;
  transition: all 0.2s ease-in-out;
  height: ${DIGIT_HEIGHT};

  ${props => transitionStates(props.$state)};
`;
