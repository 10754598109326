import { Dialog, DialogHeader, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { FileBrowserProvider } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { PickerBreadcrumbs } from '../PickerBreadcrumbs';
import { PickerFooter } from '../PickerFooter';
import { PickerProvider } from '../PickerProvider';
import { PickerRootWarning } from '../PickerRootWarning';
import { PickerTable } from '../PickerTable';
const KIND_MAP = {
    folder: EntryKind.Folder,
    document: EntryKind.Document,
};
export function PickerDialog(props) {
    const { dialogTitle, dialogSubtitle, selectType, initialEntryId, submitLabel, onClose, onSubmit, } = props;
    const selectable = (entry) => entry.kind === KIND_MAP[selectType];
    return (<FileBrowserProvider customLoadingState showSignRequestStatus selectable={selectable} parentId={initialEntryId}>
      <PickerProvider onClose={onClose} onSubmit={onSubmit}>
        <Dialog size="large">
          <DialogHeader title={dialogTitle} subtitle={dialogSubtitle} action={<IconButton $round appearance="ghost" onClick={onClose}>
                <Falcon icon="xmark"/>
              </IconButton>}/>
          <PickerBreadcrumbs />
          <PickerTable selectType={selectType}/>
          <PickerFooter submitLabel={submitLabel}/>
          <PickerRootWarning />
        </Dialog>
      </PickerProvider>
    </FileBrowserProvider>);
}
