import { coreTranslations, premiumFeaturesTranslations } from 'pleditor';
import { useActiveDocument } from 'shared/domains/documents';
import { autosave } from './autosave';
import { getColorPickerColors } from './utils';
import { useGetDocxConverterToken } from '../useGetDocxConverterToken';
import { useGetEditorToken } from '../useGetEditorToken';
export const useConfig = (options) => {
    const { id: documentId } = useActiveDocument();
    const getEditorToken = useGetEditorToken(documentId);
    const getDocxConverterToken = useGetDocxConverterToken(documentId);
    const { documentType, plugins, toolbarItems, balloonToolbarItems, refs, currentUser, featureAvailability, } = options;
    const { editorContainerRef, viewerContainerRef, viewerEditorElementRef, viewerSidebarContainerRef, sidebarContainerRef, } = refs;
    const licenseKey = process.env.PLEDITOR_LICENSE_KEY;
    const uploadUrl = process.env.PLEDITOR_IMAGE_UPLOAD_URL;
    const importConverterUrl = process.env.PLEDITOR_IMPORT_CONVERTER_URL;
    const webSocketUrl = process.env.PLEDITOR_WS_URL;
    const bundleVersion = process.env.PLEDITOR_BUNDLE_VERSION;
    const cloudServicesConfig = {
        channelId: documentId,
        tokenUrl: getEditorToken,
        webSocketUrl,
        uploadUrl,
        bundleVersion,
    };
    const featureAvailabilityFallback = Object.assign({ suggestions: false }, featureAvailability);
    const baseConfig = {
        licenseKey,
        documentId,
        currentUser,
        featureAvailability: featureAvailabilityFallback,
        translations: [coreTranslations, premiumFeaturesTranslations],
        plugins: [...plugins],
        toolbar: {
            shouldNotGroupWhenFull: false,
            items: toolbarItems,
        },
        balloonToolbar: {
            items: balloonToolbarItems,
        },
        autosave: {
            waitingTime: 3000,
            save: autosave,
        },
        comments: {
            editorConfig: {},
        },
        cloudServices: {
            tokenUrl: cloudServicesConfig.tokenUrl,
            uploadUrl: cloudServicesConfig.uploadUrl,
            webSocketUrl: cloudServicesConfig.webSocketUrl,
            bundleVersion: cloudServicesConfig.bundleVersion,
        },
        collaboration: {
            channelId: cloudServicesConfig.channelId,
        },
        table: {
            defaultHeadings: { rows: 1 },
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableProperties',
                'tableCellProperties',
            ],
        },
        fontSize: {
            supportAllValues: true,
            options: [9, 11, 13, 'default', 17, 19, 21],
        },
        fontColor: {
            columns: 12,
            colorPicker: false,
            colors: getColorPickerColors(),
        },
        fontBackgroundColor: {
            columns: 12,
            colorPicker: false,
            colors: getColorPickerColors(),
        },
        sidebar: {
            preventScrollOutOfView: true,
            container: sidebarContainerRef,
        },
        users: {
            colorsCount: 9,
        },
        restrictedEditing: {
            allowedCommands: [],
            allowedAttributes: [],
        },
    };
    const freeTextConfig = {
        revisionHistory: {
            resumeUnsavedRevision: true,
            editorContainer: editorContainerRef,
            viewerContainer: viewerContainerRef,
            viewerEditorElement: viewerEditorElementRef,
            viewerSidebarContainer: viewerSidebarContainerRef,
        },
        indentBlock: {
            classes: ['ck-custom-block-indent-1', 'ck-custom-block-indent-2', 'ck-custom-block-indent-3'],
        },
        trackChanges: {
            disableComments: true,
            mergeNestedSuggestions: true,
            trackFormatChanges: 'default',
        },
        importWord: {
            formatting: {
                resets: 'inline',
                defaults: 'inline',
                styles: 'inline',
                comments: 'basic',
            },
            tokenUrl: getDocxConverterToken,
            converterUrl: importConverterUrl,
        },
        exportWord: { tokenUrl: getDocxConverterToken },
        image: {
            insert: {
                type: 'auto',
                integrations: ['upload'],
            },
            toolbar: [
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'toggleImageCaption',
                '|',
                'resizeImage',
            ],
            resizeUnit: '%',
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    value: null,
                },
                {
                    name: 'resizeImage:80',
                    value: '80',
                },
                {
                    name: 'resizeImage:60',
                    value: '60',
                },
                {
                    name: 'resizeImage:40',
                    value: '40',
                },
            ],
        },
    };
    if (documentType === 'composed') {
        return baseConfig;
    }
    return Object.assign(Object.assign({}, baseConfig), freeTextConfig);
};
