import { AllowedValuesList } from './AllowedValuesList';
import { BooleanField } from './BooleanField';
import { DateField } from './DateField';
import { FilesystemEntriesField } from './FilesystemEntriesField';
import { MultilineTextField } from './MultilineTextField';
import { NumberField } from './NumberField';
import { MultiSelectWithInput, SingleSelectWithInput } from './SelectWithInput';
import { SimpleTextField } from './SimpleTextField';
import { UserField } from './UserField';
import { useMetadataField } from '../useMetadataField';
export function FormField() {
    const { property } = useMetadataField();
    const { type, reference, name } = property;
    if (name === 'Parties') {
        return <MultiSelectWithInput name={name}/>;
    }
    if (name === 'Counterparty') {
        return <SingleSelectWithInput name={name}/>;
    }
    if (type === 'boolean') {
        return <BooleanField />;
    }
    if (type === 'date') {
        return <DateField />;
    }
    if (type === 'list') {
        return <AllowedValuesList />;
    }
    if (type === 'simpleText') {
        return <SimpleTextField />;
    }
    if (type === 'multiLineText') {
        return <MultilineTextField />;
    }
    if (type === 'number') {
        return <NumberField />;
    }
    if (type === 'reference' && reference === 'user') {
        return <UserField />;
    }
    if (type === 'reference' && reference === 'fileSystemEntry') {
        return <FilesystemEntriesField />;
    }
    return null;
}
