export const InsightsPartiesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'InsightsPartiesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentMetadataPairs' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'property' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'name' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'eq' },
                                                                    value: { kind: 'StringValue', value: 'Parties', block: false },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'title' },
                                    name: { kind: 'Name', value: 'value' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
