import { t } from '@lingui/macro';
import { SkipDocumentContent } from '../../SkipDocumentContent';
import { ErrorAlert } from '../ErrorAlert';
import { Loader } from '../Loader';
import { Toolbar } from '../Toolbar';
import { VirtualizedList } from '../VirtualizedList';
import { usePDFData } from '../usePDFData';
import { Content, Container } from './styled';
export function Preview() {
    const { loading, error } = usePDFData();
    const endOfDocumentId = 'end-of-document';
    if (error) {
        return <ErrorAlert />;
    }
    if (loading) {
        return (<Loader message={t({ message: 'Loading PDF...', comment: 'Message when loading a PDF' })}/>);
    }
    return (<Container>
      <Toolbar />
      <Content>
        <SkipDocumentContent target={endOfDocumentId}/>
        <VirtualizedList />
        <div id={endOfDocumentId}/>
      </Content>
    </Container>);
}
