import { memo } from 'react';
import { BucketCount } from './BucketCount';
import { DateRangeCount } from './DateRangeCount';
import { MetricCount } from './MetricCount';
import { Unsupported } from '../Unsupported';
import { bucketTypeGuard, dateHistogramTypeGuard, dateRangeTypeGuard, metricTypeGuard, } from '../utils';
export const CountWidget = memo((props) => {
    const { data, aggregations, colorScheme } = props;
    const [dataPoint] = data || [];
    const [aggregation] = aggregations || [];
    const isMetric = metricTypeGuard(dataPoint);
    const isDateRange = dateRangeTypeGuard(dataPoint);
    const isDateHistogram = dateHistogramTypeGuard(dataPoint);
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    if (isMetric) {
        return <MetricCount data={dataPoint} aggregation={aggregation}/>;
    }
    if (isDateRange) {
        return <DateRangeCount data={dataPoint} aggregation={aggregation} colorScheme={colorScheme}/>;
    }
    if (isDateHistogram || isBucketAggregation) {
        return <BucketCount data={dataPoint} aggregation={aggregation} colorScheme={colorScheme}/>;
    }
    return <Unsupported />;
});
