import { Fragment } from 'react';
import { ActiveFilter, AgreementStartDateFilter, AutomaticRenewalFilter, TemplateCategoryFilter, CreatedAtFilter, UserFilter, ContractOwnerFilter, DateSignedFilter, DeviateFromStandardFilter, ESigningFilter, MaterialAgreementFilter, NoticeDateFilter, NoticePeriodFilter, OriginalTermFilter, RenewalTermFilter, SharedFilter, TerminationDateFilter, TotalContractCostFilter, TotalContractValueFilter, UpdatedAtFilter, DocumentEntityTypeFilter, OutsourcingFilter, SearchFiltersToolbar, RemoveAllButton, SaveViewButton, NoticePeriodDaysFilter, ProbationaryPeriodExpirationDate, PaymentTermsDaysFromInvoiceDateFilter, ProbationaryPeriodFilter, DiscountFilter, PostTerminationObligationsFilter, FeeAdjustmentClauseFilter, LiabilityCapFilter, LimitationOfLiabilityFilter, TerminationForConvenienceFilter, IndemnityFilter, TerminationForCauseFilter, FilesystemPathFilter, DocumentStatusFilter, MetadataAllowedValuesFilter, PairSelectFilter, } from 'app/domains/global-search';
export function DefaultSearchFilters() {
    return (<SearchFiltersToolbar actions={<Fragment>
          <RemoveAllButton />
          <SaveViewButton />
        </Fragment>} filters={<DocumentEntityTypeFilter />} filterItems={[
            { name: 'mpath', component: <FilesystemPathFilter />, inDropdown: false },
            { name: 'templateCategory', component: <TemplateCategoryFilter /> },
            { name: 'createdAt', component: <CreatedAtFilter /> },
            { name: 'updatedAt', component: <UpdatedAtFilter /> },
            { name: 'user', component: <UserFilter /> },
            { name: 'esigning', component: <ESigningFilter /> },
            { name: 'shared', component: <SharedFilter /> },
            { name: 'materialAgreement', component: <MaterialAgreementFilter /> },
            {
                name: 'documentType',
                component: <MetadataAllowedValuesFilter name="DocumentType" paramKey="documentType"/>,
            },
            { name: 'terminationDate', component: <TerminationDateFilter /> },
            { name: 'noticeDate', component: <NoticeDateFilter /> },
            { name: 'contractOwner', component: <ContractOwnerFilter /> },
            {
                name: 'counterParty',
                component: <PairSelectFilter name="Counterparty" paramKey="counterParty"/>,
            },
            { name: 'parties', component: <PairSelectFilter name="Parties" paramKey="parties"/> },
            { name: 'renewalTerm', component: <RenewalTermFilter /> },
            { name: 'noticePeriod', component: <NoticePeriodFilter /> },
            { name: 'noticePeriodDays', component: <NoticePeriodDaysFilter /> },
            { name: 'totalContractValue', component: <TotalContractValueFilter /> },
            { name: 'totalContractCost', component: <TotalContractCostFilter /> },
            { name: 'originalTerm', component: <OriginalTermFilter /> },
            { name: 'automaticRenewal', component: <AutomaticRenewalFilter /> },
            { name: 'deviateFromStandard', component: <DeviateFromStandardFilter /> },
            { name: 'active', component: <ActiveFilter /> },
            { name: 'agreementStartDate', component: <AgreementStartDateFilter /> },
            { name: 'dateSigned', component: <DateSignedFilter /> },
            {
                name: 'currency',
                component: <MetadataAllowedValuesFilter name="Currency" paramKey="currency"/>,
            },
            {
                name: 'governingLaw',
                component: <MetadataAllowedValuesFilter name="GoverningLaw" paramKey="governingLaw"/>,
            },
            {
                name: 'processingOfPersonalData',
                component: (<MetadataAllowedValuesFilter name="ProcessingOfPersonalData" paramKey="processingOfPersonalData"/>),
            },
            { name: 'outsourcing', component: <OutsourcingFilter /> },
            {
                name: 'documentCategory',
                component: (<MetadataAllowedValuesFilter name="DocumentCategory" paramKey="documentCategory"/>),
            },
            {
                name: 'paymentTermsDaysFromInvoiceDate',
                component: <PaymentTermsDaysFromInvoiceDateFilter />,
            },
            { name: 'probationaryPeriod', component: <ProbationaryPeriodFilter /> },
            {
                name: 'probationaryPeriodExpirationDate',
                component: <ProbationaryPeriodExpirationDate />,
            },
            { name: 'discount', component: <DiscountFilter /> },
            { name: 'feeAdjustmentClause', component: <FeeAdjustmentClauseFilter /> },
            { name: 'postTerminationObligations', component: <PostTerminationObligationsFilter /> },
            { name: 'liabilityCap', component: <LiabilityCapFilter /> },
            { name: 'limitationOfLiability', component: <LimitationOfLiabilityFilter /> },
            { name: 'terminationForConvenience', component: <TerminationForConvenienceFilter /> },
            { name: 'indemnity', component: <IndemnityFilter /> },
            { name: 'terminationForCause', component: <TerminationForCauseFilter /> },
            { name: 'documentStatus', component: <DocumentStatusFilter /> },
            {
                name: 'liabilityCapScope',
                component: (<MetadataAllowedValuesFilter name="LiabilityCapScope" paramKey="liabilityCapScope"/>),
            },
        ]}/>);
}
