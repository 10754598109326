import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, Button, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PickFolderDialog } from 'shared/domains/common-ui';
import { useUpload } from '../../UploadProvider';
import { Container, Spacer } from './styled';
export function SelectFolder() {
    const { selectedFolder, updateSelectedFolder } = useUpload();
    const [renderDialog, setRenderDialog] = useState(false);
    const ROOT_FOLDER_NAME = t({
        message: 'Root',
        comment: 'Upload documents dialog - Root folder label',
    });
    const selectedFolderName = (selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.name) || ROOT_FOLDER_NAME;
    const showDialog = () => {
        setRenderDialog(true);
    };
    const hideDialog = () => {
        setRenderDialog(false);
    };
    const handleSubmit = (newFolder) => {
        updateSelectedFolder(newFolder);
        hideDialog();
    };
    return (<Container>
      <Typography $fontSize="small" $fontWeight="medium">
        <Trans comment="Upload documents dialog - Choose folder label">Choose folder</Trans>
      </Typography>
      <Spacer>
        <AvatarWithLabel appearance="primary" title={selectedFolderName}>
          <Falcon icon="folder"/>
        </AvatarWithLabel>
        <Button appearance="ghost" onClick={showDialog}>
          <Trans comment="Upload documents dialog - Choose folder button label">
            Choose folder
          </Trans>
        </Button>
      </Spacer>
      {renderDialog && (<PickFolderDialog dialogTitle={t({
                comment: 'Choose folder dialog - Choose folder title',
                message: 'Choose folder',
            })} dialogSubtitle={t({
                comment: 'Choose folder dialog - Choose folder description',
                message: 'Choose where you want to save the document',
            })} submitLabel={t({
                comment: 'Choose folder dialog - Save here button',
                message: 'Save here',
            })} initialEntryId={selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.id} onSubmit={handleSubmit} onClose={hideDialog}/>)}
    </Container>);
}
