import { t } from '@lingui/macro';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { MultiSelectFilter } from './MultiSelectFilter';
export const esigningStatusLabel = () => t({ message: 'eSigning status', comment: 'Insights filter: Esigning status' });
export function EsigningStatus() {
    const items = [
        {
            id: SignRequestStatus.Pending,
            title: t({
                message: 'Pending',
                comment: 'Insights: Esigning status: Pending',
            }),
        },
        {
            id: SignRequestStatus.Signed,
            title: t({
                message: 'Signed',
                comment: 'Insights: Esigning status: Signed',
            }),
        },
        {
            id: SignRequestStatus.Rejected,
            title: t({
                message: 'Rejected',
                comment: 'Insights: Esigning status: Rejected',
            }),
        },
    ];
    return (<MultiSelectFilter name="filters.metadataDocumentEsigningStatus" items={items} label={esigningStatusLabel()}/>);
}
