import { useState } from 'react';
import { Box } from '@pocketlaw/tetris';
import { BucketTable } from './BucketTable';
import { BucketTabs } from './BucketTabs';
import { TopHitsTable } from './TopHitsTable';
import { getNextAggregation, hasBucketedBreakdown } from './utils';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { Unsupported } from '../Unsupported';
import { bucketTypeGuard, topHitsTypeGuard } from '../utils';
export function TableWidget(props) {
    const { data, aggregations } = props;
    const [dataPoint] = data || [];
    const [activeBucketId, setActiveBucketId] = useState();
    const hasBreakdown = hasBucketedBreakdown(dataPoint);
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    const isTopHitsAggregation = topHitsTypeGuard(dataPoint);
    const nextAggregation = getNextAggregation(aggregations);
    if (isTopHitsAggregation) {
        return <TopHitsTable data={data} aggregations={nextAggregation}/>;
    }
    if (isBucketAggregation && !hasBreakdown) {
        return <BucketTable data={data} aggregations={nextAggregation}/>;
    }
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    const { id: defaultActiveBucketId } = dataPoint.buckets.find(bucket => bucket.id !== TERMS_AGGREGATION_MISSING_VALUE_NAME) || {};
    const currentlyActiveBucketId = activeBucketId || defaultActiveBucketId || '';
    const activeBucket = dataPoint.buckets.find(bucket => bucket.id === currentlyActiveBucketId);
    return (<Box flexDirection="column" width="100%" height="100%">
      {hasBreakdown && (<BucketTabs data={data} aggregations={aggregations} onClick={setActiveBucketId} activeBucket={currentlyActiveBucketId}/>)}

      {activeBucket && (<TableWidget key={activeBucket.id} data={activeBucket.aggregations} aggregations={nextAggregation}/>)}
    </Box>);
}
