import { useGetData } from './useGetData';
import { MultiSelectFilter, useMultiselectFilter } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
/**
 * Generic component that can be used to filter on any metadata property that allows
 * user to insert one or more values for a metadata pair.
 */
export function PairSelectFilter(props) {
    const { name, paramKey } = props;
    const { data, loading } = useGetData(name);
    const filter = useMultiselectFilter({
        items: data,
        paramKey,
        name: searchFilterCopy()[paramKey],
        loading,
    });
    return <MultiSelectFilter filter={filter}/>;
}
