import { dateHistogramTypeGuard } from './utils';
export function getDateHistogramDiff(dataPoint) {
    if (!dateHistogramTypeGuard(dataPoint)) {
        return undefined;
    }
    const [dataPointOne, dataPointTwo] = dataPoint.buckets.reverse();
    const { value: valueOne = 0 } = dataPointOne || {};
    const { value: valueTwo = 0 } = dataPointTwo || {};
    const diffValue = valueOne - valueTwo;
    if (typeof diffValue === 'number') {
        return diffValue;
    }
    return undefined;
}
