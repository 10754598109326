import { t } from '@lingui/macro';
import { useField } from 'formik';
import { SingleSelectFilter } from './SingleSelectFilter';
export function BooleanFilter(props) {
    const { name, label } = props;
    const [field, meta, helpers] = useField(name);
    const items = [
        {
            id: 'true',
            value: { eq: true },
            title: t({ message: 'Yes', comment: 'Insights: Boolean filter - yes' }),
        },
        {
            id: 'false',
            value: { eq: false },
            title: t({ message: 'No', comment: 'Insights: Boolean filter - yes' }),
        },
        {
            id: 'not-set',
            value: { isNull: true },
            title: t({ message: 'Not set', comment: 'Insights: Boolean filter - has no value set' }),
        },
        {
            id: 'set',
            value: { isNull: false },
            title: t({
                message: 'Has any value',
                comment: 'Insights: Boolean filter - has any value set',
            }),
        },
    ];
    const selectedItem = items.find(item => {
        const currentValue = JSON.stringify(field.value);
        const value = JSON.stringify(item.value);
        return currentValue === value;
    });
    const handleOnChange = (selected) => {
        const { value } = selected;
        helpers.setValue(value);
        helpers.setTouched(true);
    };
    const handleReset = () => {
        helpers.setValue(undefined);
    };
    const initiallyOpen = !(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id) && !meta.touched;
    return (<SingleSelectFilter name={field.name} items={items} label={label} onChange={handleOnChange} selectedId={selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id} onReset={handleReset} initiallyOpen={initiallyOpen}/>);
}
