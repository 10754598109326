import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { MenuMultiSelectItem, SearchableMenu } from 'shared/domains/common-ui';
import { ActiveFilter, activeLabel } from './ActiveFilter';
import { AutomaticRenewal, automaticRenewalLabel } from './AutomaticRenewal';
import { ContractCreator, contractCreatorLabel } from './ContractCreator';
import { ContractOwner, contractOwnerLabel } from './ContractOwner';
import { TotalContractValue, totalContractValueLabel } from './ContractValue';
import { CreatedAt, createdAtLabel } from './CreatedAt';
import { Currency, currencyLabel } from './Currency';
import { DateSigned, dateSignedLabel } from './DateSigned';
import { DeviateFromStandard, deviateFromStandardLabel } from './DeviateFromStandard';
import { DiscountFilter, discountLabel } from './DiscountFilter';
import { DocumentCategory, documentCategoryLabel } from './DocumentCategory';
import { DocumentStartDate, documentStartDateLabel } from './DocumentStartDate';
import { DocumentStatus, documentStatusLabel } from './DocumentStatus';
import { DocumentType, documentTypeLabel } from './DocumentType';
import { EntityTypeFilter, entityTypeLabel } from './EntityTypeFilter';
import { EsigningStatus, esigningStatusLabel } from './EsigningStatus';
import { FeeAdjustmentClause, feeAdjustmentClauseLabel } from './FeeAdjustmentClause';
import { GoverningLaw, governingLawLabel } from './GoverningLaw';
import { Indemnity, indemnityLabel } from './Indemnity';
import { LiabilityCap, liabilityCapLabel } from './LiabilityCap';
import { LiabilityCapScope, liabilityCapScopeLabel } from './LiabilityCapScope';
import { LimitationOfLiability, limitationOfLiabilityLabel } from './LimitationOfLiability';
import { MaterialAgreement, materialAgreementLabel } from './MaterialAgreement';
import { NoticeDate, noticeDateLabel } from './NoticeDate';
import { NoticePeriodDays, noticePeriodDaysLabel } from './NoticePeriodDays';
import { NoticePeriodMonths, noticePeriodMonthsLabel } from './NoticePeriodMonths';
import { OriginalTermMonths, originalTermMonthsLabel } from './OriginalTermMonths';
import { Outsourcing, outsourcingLabel } from './Outsourcing';
import { Parties, partiesLabel } from './Parties';
import { PaymentTermsDays, paymentTermsDaysLabel } from './PaymentTermsDays';
import { PostTerminationObligations, postTerminationObligationsLabel, } from './PostTerminationObligations';
import { ProbationaryPeriod, probationaryPeriodLabel } from './ProbationaryPeriod';
import { ProbationaryPeriodExpirationDate, probationaryPeriodExpirationDateLabel, } from './ProbationaryPeriodExpirationDate';
import { ProcessingOfPersonalData, processingOfPersonalDataLabel, } from './ProcessingOfPersonalData';
import { RenewalTermMonths, renewalTermMonthsLabel } from './RenewalTermMonths';
import { SharedExternally, sharedExternallyLabel } from './SharedExternally';
import { TemplateCategory, templateCategoryLabel } from './TemplateCategory';
import { TerminationDate, terminationDateLabel } from './TerminationDate';
import { TerminationForCause, terminationForCauseLabel } from './TerminationForCause';
import { TerminationForConvenience, terminationForConvenienceLabel, } from './TerminationForConvenience';
import { TotalContractCost, totalContractCostLabel } from './TotalContractCost';
import { UpdatedAt, updatedAtLabel } from './UpdatedAt';
import { useFilterDraft } from './useFilterDraft';
export function FilterSelection() {
    const [field, , helpers] = useField('filters');
    const draft = useFilterDraft();
    const filters = [
        {
            id: 'createdByUserId',
            component: <ContractCreator />,
            name: contractCreatorLabel(),
        },
        {
            id: 'entityType',
            component: <EntityTypeFilter />,
            name: entityTypeLabel(),
        },
        {
            id: 'categoryId',
            component: <TemplateCategory />,
            name: templateCategoryLabel(),
        },
        {
            id: 'metadataDocumentStatus',
            component: <DocumentStatus />,
            name: documentStatusLabel(),
        },
        {
            id: 'metadataDocumentActive',
            component: <ActiveFilter />,
            name: activeLabel(),
        },
        {
            id: 'metadataDocumentAutomaticRenewal',
            component: <AutomaticRenewal />,
            name: automaticRenewalLabel(),
        },
        {
            id: 'metadataDocumentDeviateFromStandard',
            component: <DeviateFromStandard />,
            name: deviateFromStandardLabel(),
        },
        {
            id: 'metadataDocumentMaterialAgreement',
            component: <MaterialAgreement />,
            name: materialAgreementLabel(),
        },
        {
            id: 'metadataDocumentOutsourcing',
            component: <Outsourcing />,
            name: outsourcingLabel(),
        },
        {
            id: 'metadataDocumentStartDate',
            component: <DocumentStartDate />,
            name: documentStartDateLabel(),
        },
        {
            id: 'createdAt',
            component: <CreatedAt />,
            name: createdAtLabel(),
        },
        {
            id: 'updatedAt',
            component: <UpdatedAt />,
            name: updatedAtLabel(),
        },
        {
            id: 'metadataDocumentDateSigned',
            component: <DateSigned />,
            name: dateSignedLabel(),
        },
        {
            id: 'metadataDocumentNoticeDate',
            component: <NoticeDate />,
            name: noticeDateLabel(),
        },
        {
            id: 'metadataDocumentTerminationDate',
            component: <TerminationDate />,
            name: terminationDateLabel(),
        },
        {
            id: 'metadataDocumentContractOwner',
            component: <ContractOwner />,
            name: contractOwnerLabel(),
        },
        {
            id: 'metadataDocumentParties',
            component: <Parties />,
            name: partiesLabel(),
        },
        {
            id: 'metadataDocumentCurrency',
            component: <Currency />,
            name: currencyLabel(),
        },
        {
            id: 'metadataDocumentDocumentCategory',
            component: <DocumentCategory />,
            name: documentCategoryLabel(),
        },
        {
            id: 'metadataDocumentDocumentType',
            component: <DocumentType />,
            name: documentTypeLabel(),
        },
        {
            id: 'metadataDocumentEsigningStatus',
            component: <EsigningStatus />,
            name: esigningStatusLabel(),
        },
        {
            id: 'metadataDocumentGoverningLaw',
            component: <GoverningLaw />,
            name: governingLawLabel(),
        },
        {
            id: 'metadataDocumentIsSharedExternally',
            component: <SharedExternally />,
            name: sharedExternallyLabel(),
        },
        {
            id: 'metadataDocumentNoticePeriodDays',
            component: <NoticePeriodDays />,
            name: noticePeriodDaysLabel(),
        },
        {
            id: 'metadataDocumentNoticePeriodMonths',
            component: <NoticePeriodMonths />,
            name: noticePeriodMonthsLabel(),
        },
        {
            id: 'metadataDocumentOriginalTermMonths',
            component: <OriginalTermMonths />,
            name: originalTermMonthsLabel(),
        },
        {
            id: 'metadataDocumentRenewalTermMonths',
            component: <RenewalTermMonths />,
            name: renewalTermMonthsLabel(),
        },
        {
            id: 'metadataDocumentTotalContractCost',
            component: <TotalContractCost />,
            name: totalContractCostLabel(),
        },
        {
            id: 'metadataDocumentTotalContractValue',
            component: <TotalContractValue />,
            name: totalContractValueLabel(),
        },
        {
            id: 'metadataDocumentProcessingOfPersonalData',
            component: <ProcessingOfPersonalData />,
            name: processingOfPersonalDataLabel(),
        },
        {
            id: 'metadataDocumentLiabilityCapScope',
            component: <LiabilityCapScope />,
            name: liabilityCapScopeLabel(),
        },
        {
            id: 'metadataDocumentDiscount',
            component: <DiscountFilter />,
            name: discountLabel(),
        },
        {
            id: 'metadataDocumentFeeAdjustmentClause',
            component: <FeeAdjustmentClause />,
            name: feeAdjustmentClauseLabel(),
        },
        {
            id: 'metadataDocumentIndemnity',
            component: <Indemnity />,
            name: indemnityLabel(),
        },
        {
            id: 'metadataDocumentLiabilityCap',
            component: <LiabilityCap />,
            name: liabilityCapLabel(),
        },
        {
            id: 'metadataDocumentLimitationOfLiability',
            component: <LimitationOfLiability />,
            name: limitationOfLiabilityLabel(),
        },
        {
            id: 'metadataDocumentPaymentTermsDaysFromInvoiceDate',
            component: <PaymentTermsDays />,
            name: paymentTermsDaysLabel(),
        },
        {
            id: 'metadataDocumentPostTerminationObligations',
            component: <PostTerminationObligations />,
            name: postTerminationObligationsLabel(),
        },
        {
            id: 'metadataDocumentProbationaryPeriod',
            component: <ProbationaryPeriod />,
            name: probationaryPeriodLabel(),
        },
        {
            id: 'metadataDocumentProbationaryPeriodExpirationDate',
            component: <ProbationaryPeriodExpirationDate />,
            name: probationaryPeriodExpirationDateLabel(),
        },
        {
            id: 'metadataDocumentTerminationForCause',
            component: <TerminationForCause />,
            name: terminationForCauseLabel(),
        },
        {
            id: 'metadataDocumentTerminationForConvenience',
            component: <TerminationForConvenience />,
            name: terminationForConvenienceLabel(),
        },
    ].sort((a, b) => a.name.localeCompare(b.name));
    const searchableItems = filters.map(({ id, name }) => ({ id, name }));
    const activeFilterKeys = Object.entries(field.value || {})
        .filter(([key, value]) => (value != null ? key : undefined))
        .map(([key]) => key);
    const handleToggleFilter = (id) => {
        const isSelected = activeFilterKeys.includes(id);
        if (isSelected) {
            draft.clear();
            const newFilterData = Object.assign(Object.assign({}, field.value), { [id]: undefined });
            helpers.setValue(newFilterData);
        }
        else {
            draft.set(`${field.name}.${id}`);
        }
    };
    return (<Fragment>
      <SearchableMenu items={searchableItems} keys={['name']} placeholder={t({
            message: 'Search',
            comment: 'Insights widget form: Filter selection search input',
        })} renderTarget={({ setOpen, open }) => (<Button type="button" size="small" appearance="secondary" onClick={() => setOpen(!open)}>
            <Falcon icon="plus"/>
            <Trans comment="Insights: Widget form add filter">Add filter</Trans>
          </Button>)}>
        {({ items, setOpen }) => items.map(item => (<MenuMultiSelectItem key={item.id} selected={activeFilterKeys.includes(item.id)} onClick={() => {
                handleToggleFilter(item.id);
                setOpen(false);
            }}>
              {item.name}
            </MenuMultiSelectItem>))}
      </SearchableMenu>

      {filters.map(filter => (<Fragment key={filter.id}>{filter.component}</Fragment>))}
    </Fragment>);
}
