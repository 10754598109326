import { t, Trans, plural } from '@lingui/macro';
import { TabNav, Tabs, Tab, TabContent } from '@pocketlaw/tetris';
import { DrawerHeader } from './components/DrawerHeader';
import { TeamAccessSection } from './components/TeamAccessSection';
import { UserAccessSection } from './components/UserAccessSection';
import { useComposerName } from './hooks/useComposerName';
import { StyledDrawer, ContentContainer, StyledAlert, AccessSectionContainer } from './styled';
export { GetAccessByComposersDocument } from './hooks/useAccessByComposers/GetAccessByComposers.gql';
export function ContractAccessDrawer(props) {
    const { onClose, totalComposers } = props;
    const { composerName } = useComposerName();
    const subtitle = totalComposers === 1
        ? composerName
        : t({
            comment: 'Contract access drawer: Subtitle',
            message: plural(totalComposers, {
                one: '# selected template',
                other: '# selected templates',
            }),
        });
    return (<StyledDrawer position="right" open>
      <Tabs stretch>
        <DrawerHeader onClose={onClose} title={t({ comment: 'Contract access drawer: Title', message: 'Manage access' })} subTitle={subtitle}>
          <TabNav>
            <Tab id="access">
              <Trans comment="Contract access drawer: Access tab">Access</Trans>
            </Tab>
            {totalComposers > 1 && (<Tab id="partial">
                <Trans comment="Contract access drawer: Partial tab">Partial</Trans>
              </Tab>)}
            <Tab id="noAccess">
              <Trans comment="Contract access drawer: No access tab">No access</Trans>
            </Tab>
          </TabNav>
        </DrawerHeader>
        <ContentContainer>
          <StyledAlert appearance="primary" title={t({
            comment: 'Contract access drawer content alert: Title',
            message: 'What to do?',
        })} message={t({
            comment: 'Contract access drawer content alert: Paragraph',
            message: 'Decide what users in your company that should be able to see & use the selected templates.',
        })}/>
          <TabContent tabId="access">
            <AccessSectionContainer>
              <TeamAccessSection accessDropdownPermission="access"/>
            </AccessSectionContainer>
            <AccessSectionContainer>
              <UserAccessSection accessDropdownPermission="access"/>
            </AccessSectionContainer>
          </TabContent>
          {totalComposers > 1 && (<TabContent tabId="partial">
              <AccessSectionContainer>
                <TeamAccessSection accessDropdownPermission="partialAccess"/>
              </AccessSectionContainer>
              <AccessSectionContainer>
                <UserAccessSection accessDropdownPermission="partialAccess"/>
              </AccessSectionContainer>
            </TabContent>)}
          <TabContent tabId="noAccess">
            <AccessSectionContainer>
              <TeamAccessSection accessDropdownPermission="noAccess"/>
            </AccessSectionContainer>
            <AccessSectionContainer>
              <UserAccessSection accessDropdownPermission="noAccess"/>
            </AccessSectionContainer>
          </TabContent>
        </ContentContainer>
      </Tabs>
    </StyledDrawer>);
}
