import { Trans } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { Unsupported } from '../Unsupported';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
import { bucketTypeGuard } from '../utils';
import { ButtonTab } from './styled';
export function BucketTabs(props) {
    const { data, onClick, activeBucket, aggregations } = props;
    const [dataPoint] = data || [];
    const [aggregation] = aggregations;
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    const { buckets } = dataPoint;
    const field = getSelectedField(aggregation);
    const entries = buckets
        .filter(bucket => bucket.name !== TERMS_AGGREGATION_MISSING_VALUE_NAME)
        .map(bucket => ({
        id: bucket.id,
        name: bucket.name,
    }));
    const missingTab = buckets.find(bucket => bucket.name === TERMS_AGGREGATION_MISSING_VALUE_NAME);
    return (<Box gap="md" bg="background.75" p="sm" mt="xl" borderRadius="md" width="fit-content" alignSelf="flex-start">
      {entries.map(bucket => (<ButtonTab key={bucket.name} $active={bucket.id === activeBucket} onClick={() => onClick(bucket.id)}>
          <Typography $truncate>{fieldDisplayValue(field, bucket.name)}</Typography>
        </ButtonTab>))}
      {missingTab && (<ButtonTab key={missingTab.name} $active={missingTab.name === activeBucket} onClick={() => onClick(missingTab.id)}>
          <Typography $truncate>
            <Trans comment="Insights table tabs: Missing tab">Missing</Trans>
          </Typography>
        </ButtonTab>)}
    </Box>);
}
