import { metadataConfig } from 'shared/domains/metadata';
import { MultiSelectFilter } from './MultiSelectFilter';
/**
 * This is a generic multiselect filter that can be used to filter on any metadata property that has allowed values.
 */
export function MetadataAllowedValues(props) {
    var _a, _b;
    const { name, field, label } = props;
    const items = ((_b = (_a = metadataConfig.get(name)) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.map(type => ({
        id: type.value,
        title: type.text,
    }))) || [];
    return <MultiSelectFilter name={`filters.${field}`} items={items} label={label}/>;
}
