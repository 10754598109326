import { AvatarWithLabel, Radio, TableBodyCell, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { UserAvatarWithLabel } from 'app/domains/users';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { StatusChip } from './StatusChip';
import { StyledButton, StyledTableRow } from './styled';
export function Row(props) {
    const { entry, showSelectCell } = props;
    const { id, kind, name, status, updatedAt, owner, disabled } = entry;
    const { selected, onSelect } = useFileBrowserContext();
    const handleClick = () => onSelect(entry);
    const handleChange = () => onSelect(entry);
    const checked = (selected === null || selected === void 0 ? void 0 : selected.id) === id;
    const ownerName = (owner === null || owner === void 0 ? void 0 : owner.name) || '';
    const avatarProps = {
        size: 'sm',
        title: name,
        appearance: kind === EntryKind.Folder ? 'dark' : 'light',
        shape: kind === EntryKind.Folder ? 'square' : 'circle',
    };
    const localizedUpdatedAt = localizedFormatDate(updatedAt, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
    const updatedAtElement = (<Typography $fontSize="small" $appearance="300">
      {localizedUpdatedAt}
    </Typography>);
    if (kind === EntryKind.Folder) {
        return (<StyledTableRow $hoverCursor onClick={handleClick}>
        <TableBodyCell headers="cell:name">
          <StyledButton onClick={handleClick}>
            <AvatarWithLabel {...avatarProps}>
              <Falcon icon="folder"/>
            </AvatarWithLabel>
          </StyledButton>
        </TableBodyCell>
        <TableBodyCell headers="cell:updated">{updatedAtElement}</TableBodyCell>
        <TableBodyCell headers="cell:status"/>
        <TableBodyCell headers="cell:owner"/>
        {showSelectCell && <TableBodyCell headers="cell:select"/>}
      </StyledTableRow>);
    }
    return (<StyledTableRow $disabled={disabled} disabled={disabled}>
      <TableBodyCell headers="cell:name">
        <AvatarWithLabel {...avatarProps}>
          <Falcon icon="file"/>
        </AvatarWithLabel>
      </TableBodyCell>
      <TableBodyCell headers="cell:updated">{updatedAtElement}</TableBodyCell>
      <TableBodyCell headers="cell:status">
        <StatusChip status={status}/>
      </TableBodyCell>
      <TableBodyCell headers="cell:owner">
        <UserAvatarWithLabel title={ownerName} size={avatarProps.size}/>
      </TableBodyCell>
      {showSelectCell && (<TableBodyCell headers="cell:select">
          <Radio checked={checked} onChange={handleChange}/>
        </TableBodyCell>)}
    </StyledTableRow>);
}
