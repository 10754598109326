import { useEffect } from 'react';
import { useFormikContext } from 'formik';
export function HandleFilterChanges() {
    const { values, submitForm, dirty } = useFormikContext();
    useEffect(() => {
        if (dirty) {
            submitForm();
        }
    }, [values, submitForm, dirty]);
    return null;
}
