import { Fragment } from 'react';
import { Box } from '@pocketlaw/tetris';
import { Alerts } from '../Alerts';
import { Empty } from '../Empty';
import { Item } from '../Item';
import { useAttachments } from '../Provider';
import { UploadAttachmentDropdown } from '../UploadAttachmentDropdown';
export function Attachments() {
    const { attachments } = useAttachments();
    const amount = attachments.length;
    const empty = amount === 0;
    return (<Fragment>
      <Alerts />
      {empty ? (<Empty />) : (<Box flexDirection="column" gap="2xl">
          <Box flexDirection="column" gap="md">
            {attachments.map(attachment => (<Item key={attachment.path} {...attachment}/>))}
          </Box>
          <Box alignItems="center" justifyContent="center">
            <UploadAttachmentDropdown origin="listAction"/>
          </Box>
        </Box>)}
    </Fragment>);
}
