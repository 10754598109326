import { useField } from 'formik';
export function useFilterDraft() {
    const [field, , helpers] = useField('filterDraft');
    const set = (draft) => helpers.setValue(draft);
    const clear = () => helpers.setValue('');
    return {
        set,
        clear,
        value: field.value,
    };
}
