import { TableBodyCell, TableCellEmpty } from '@pocketlaw/tetris';
import { ActionCell } from '../cells/ActionCell';
import { AmountCell } from '../cells/AmountCell';
import { BooleanCell } from '../cells/BooleanCell';
import { ContractOwnerCell } from '../cells/ContractOwnerCell';
import { DateCell } from '../cells/DateCell';
import { DocumentStatusCell } from '../cells/DocumentStatusCell';
import { ESigningStatusCell } from '../cells/ESigningStatusCell';
import { MetadataAllowedValueCell } from '../cells/MetadataAllowedValueCell';
import { NotesCell } from '../cells/NotesCell';
import { NoticePeriodDays } from '../cells/NoticePeriodDays';
import { NumberCell } from '../cells/NumberCell';
import { PartiesCell } from '../cells/PartiesCell';
import { ProcessingOfPersonalDataCell } from '../cells/ProcessingOfPersonalDataCell';
import { TextCell } from '../cells/TextCell';
import { TitleCell } from '../cells/TitleCell';
import { TypeCell } from '../cells/TypeCell';
import { UserCell } from '../cells/UserCell';
export function SearchResultsTableCell(props) {
    var _a, _b;
    const { column, result, selected, onSelect } = props;
    const { id: columnId } = column;
    switch (columnId) {
        case 'name':
            return (<TitleCell headers={columnId} highlight={result.meta.highlight} entityId={result.entityId} name={result.name} entityType={result.entityType} onSelect={onSelect} selected={selected}/>);
        case 'entityType':
            return <TypeCell headers={columnId} entityType={result.entityType}/>;
        case 'createdAt':
            return <DateCell date={result.createdAt} headers={columnId}/>;
        case 'createdByUser':
            return <UserCell name={(_b = (_a = result.createdByUser) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''} headers={columnId}/>;
        case 'updatedAt':
            return <DateCell date={result.updatedAt} headers={columnId}/>;
        case 'metadataDocumentTerminationDate':
            return <DateCell date={result.metadataDocumentTerminationDate} headers={columnId}/>;
        case 'metadataDocumentNoticeDate':
            return <DateCell date={result.metadataDocumentNoticeDate} headers={columnId}/>;
        case 'metadataDocumentDateSigned':
            return <DateCell date={result.metadataDocumentDateSigned} headers={columnId}/>;
        case 'metadataDocumentStartDate':
            return <DateCell date={result.metadataDocumentStartDate} headers={columnId}/>;
        case 'metadataDocumentDocumentType':
            return (<MetadataAllowedValueCell headers={columnId} value={result.metadataDocumentDocumentType} name="DocumentType"/>);
        case 'metadataDocumentEsigningStatus':
            return (<ESigningStatusCell status={result.metadataDocumentEsigningStatus} headers={columnId}/>);
        case 'metadataDocumentContractOwner':
            return (<ContractOwnerCell userIds={result.metadataDocumentContractOwner} headers={columnId}/>);
        case 'metadataDocumentCounterparty':
            return <TextCell primary={result.metadataDocumentCounterparty} headers={columnId}/>;
        case 'metadataDocumentCurrency':
            return (<MetadataAllowedValueCell headers={columnId} value={result.metadataDocumentCurrency} name="Currency"/>);
        case 'metadataDocumentGoverningLaw':
            return (<MetadataAllowedValueCell headers={columnId} value={result.metadataDocumentGoverningLaw} name="GoverningLaw"/>);
        case 'metadataDocumentNotes':
            return <NotesCell headers={columnId} text={result.metadataDocumentNotes}/>;
        case 'metadataDocumentNoticePeriodMonths':
            return <NumberCell headers={columnId} value={result.metadataDocumentNoticePeriodMonths}/>;
        case 'metadataDocumentOriginalTermMonths':
            return <NumberCell value={result.metadataDocumentOriginalTermMonths} headers={columnId}/>;
        case 'metadataDocumentRenewalTermMonths':
            return <NumberCell headers={columnId} value={result.metadataDocumentRenewalTermMonths}/>;
        case 'metadataDocumentTotalContractCost':
            return (<AmountCell headers={columnId} amount={result.metadataDocumentTotalContractCost} currency={result.metadataDocumentCurrency}/>);
        case 'metadataDocumentTotalContractValue':
            return (<AmountCell headers={columnId} amount={result.metadataDocumentTotalContractValue} currency={result.metadataDocumentCurrency}/>);
        case 'metadataDocumentMaterialAgreement':
            return <BooleanCell headers={columnId} value={result.metadataDocumentMaterialAgreement}/>;
        case 'metadataDocumentDeviateFromStandard':
            return <BooleanCell headers={columnId} value={result.metadataDocumentDeviateFromStandard}/>;
        case 'metadataDocumentAutomaticRenewal':
            return <BooleanCell headers={columnId} value={result.metadataDocumentAutomaticRenewal}/>;
        case 'metadataDocumentIsSharedExternally':
            return <BooleanCell headers={columnId} value={result.metadataDocumentIsSharedExternally}/>;
        case 'metadataDocumentActive':
            return <BooleanCell headers={columnId} value={result.metadataDocumentActive}/>;
        case 'metadataDocumentOutsourcing':
            return <BooleanCell headers={columnId} value={result.metadataDocumentOutsourcing}/>;
        case 'metadataDocumentProcessingOfPersonalData':
            return (<ProcessingOfPersonalDataCell headers={columnId} value={result.metadataDocumentProcessingOfPersonalData}/>);
        case 'metadataDocumentDocumentCategory':
            return (<MetadataAllowedValueCell headers={columnId} value={result.metadataDocumentDocumentCategory} name="DocumentCategory"/>);
        case 'metadataDocumentNoticePeriodDays':
            return (<NoticePeriodDays headers={columnId} value={result.metadataDocumentNoticePeriodDays}/>);
        case 'metadataDocumentStatus':
            return (<DocumentStatusCell status={result.metadataDocumentStatus} entityId={result.id} documentId={result.entityId} 
            // eslint-disable-next-line no-underscore-dangle
            typename={result.__typename || ''} entityType={result.entityType} headers={columnId}/>);
        case 'metadataDocumentDiscount':
            return <BooleanCell headers={columnId} value={result.metadataDocumentDiscount}/>;
        case 'metadataDocumentFeeAdjustmentClause':
            return <BooleanCell headers={columnId} value={result.metadataDocumentFeeAdjustmentClause}/>;
        case 'metadataDocumentIndemnity':
            return <BooleanCell headers={columnId} value={result.metadataDocumentIndemnity}/>;
        case 'metadataDocumentLiabilityCap':
            return <NumberCell headers={columnId} value={result.metadataDocumentLiabilityCap}/>;
        case 'metadataDocumentLiabilityCapScope':
            return (<MetadataAllowedValueCell headers={columnId} value={result.metadataDocumentLiabilityCapScope} name="LiabilityCapScope"/>);
        case 'metadataDocumentLimitationOfLiability':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentLimitationOfLiability}/>);
        case 'metadataDocumentPaymentTermsDaysFromInvoiceDate':
            return (<NumberCell headers={columnId} value={result.metadataDocumentPaymentTermsDaysFromInvoiceDate}/>);
        case 'metadataDocumentPostTerminationObligations':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentPostTerminationObligations}/>);
        case 'metadataDocumentProbationaryPeriod':
            return <BooleanCell headers={columnId} value={result.metadataDocumentProbationaryPeriod}/>;
        case 'metadataDocumentProbationaryPeriodExpirationDate':
            return (<DateCell date={result.metadataDocumentProbationaryPeriodExpirationDate} headers={columnId}/>);
        case 'metadataDocumentTerminationForCause':
            return <BooleanCell headers={columnId} value={result.metadataDocumentTerminationForCause}/>;
        case 'metadataDocumentTerminationForConvenience':
            return (<BooleanCell headers={columnId} value={result.metadataDocumentTerminationForConvenience}/>);
        case 'metadataDocumentParties':
            return <PartiesCell headers={columnId} data={result.metadataDocumentParties}/>;
        case 'action':
            return <ActionCell headers={column.id} result={result} stickyColumn="right"/>;
        default:
            return (<TableBodyCell headers={columnId}>
          <TableCellEmpty />
        </TableBodyCell>);
    }
}
