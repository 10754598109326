import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Menu, MenuList, MenuItem, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAttachments } from '../../Provider';
export function Dropdown(props) {
    const { origin, onUpload, onSelect } = props;
    const { disabled, uploadingAttachment } = useAttachments();
    const [open, setOpen] = useState(false);
    const handleShowMenu = () => setOpen(true);
    const handleHideMenu = () => setOpen(false);
    const handleSelect = () => {
        handleHideMenu();
        onSelect();
    };
    const handleUpload = () => {
        handleHideMenu();
        onUpload();
    };
    const buttonProps = {
        disabled: disabled || uploadingAttachment,
        onClick: handleShowMenu,
    };
    const emptyStateButton = (<Button appearance="primary" size="small" {...buttonProps}>
      <Falcon icon="plus"/>
      <Trans comment="No attachments added empty state - add attachment label">Add</Trans>
    </Button>);
    const listActionButton = (<Button appearance="secondary" size="small" {...buttonProps}>
      <Falcon icon="plus"/>
      <Trans comment="Add attachment button label">Add</Trans>
    </Button>);
    const target = origin === 'emptyState' ? emptyStateButton : listActionButton;
    return (<Menu open={open} onClose={handleHideMenu} target={target}>
      <MenuBody>
        <MenuList>
          <MenuItem onClick={handleSelect}>
            <Falcon icon="folder"/>
            <Trans comment="Select document on pocketlaw button label">Select on Pocketlaw</Trans>
          </MenuItem>
          <MenuItem onClick={handleUpload}>
            <Falcon icon="up-from-bracket"/>
            <Trans comment="Upload document button label">Upload document</Trans>
          </MenuItem>
        </MenuList>
      </MenuBody>
    </Menu>);
}
