import { TableVisualization } from './TableVisualization';
import { Unsupported } from '../Unsupported';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
import { bucketTypeGuard } from '../utils';
export function BucketTable(props) {
    const { data, aggregations } = props;
    const [dataPoint] = data || [];
    const [aggregation] = aggregations;
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    const field = getSelectedField(aggregation);
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    const entries = dataPoint.buckets.map(bucket => (Object.assign(Object.assign({}, bucket), { name: fieldDisplayValue(field, bucket.name) })));
    return <TableVisualization entries={entries}/>;
}
