import { metadataConfig } from 'shared/domains/metadata';
import { searchFilterCopy } from './searchFilterCopy';
import { useMultiselectFilter, MultiSelectFilter, } from '../MultiSelectFilter';
/**
 * This is a generic multiselect filter that can be used to filter on any metadata property that has allowed values.
 * It will return a searchable list of all allowed values of the given metadata property.
 */
export function MetadataAllowedValuesFilter(props) {
    var _a;
    const { name, paramKey } = props;
    const allowedValues = ((_a = metadataConfig.get(name)) === null || _a === void 0 ? void 0 : _a.allowedValues) || [];
    const items = allowedValues.reduce((result, current) => {
        const { text, value, groupValues } = current;
        result.push({ id: value, title: text });
        if (groupValues === null || groupValues === void 0 ? void 0 : groupValues.length) {
            result.push(...groupValues.map(item => ({ id: item.value, title: item.text })));
        }
        return result;
    }, []);
    const filter = useMultiselectFilter({
        paramKey,
        items,
        name: searchFilterCopy()[paramKey],
    });
    return <MultiSelectFilter filter={filter}/>;
}
