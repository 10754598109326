var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { InsightsPartiesQueryDocument } from './InsightsPartiesQuery.gql';
export function useGetParties() {
    const _a = useQuery(InsightsPartiesQueryDocument), { data } = _a, rest = __rest(_a, ["data"]);
    const { documentMetadataPairs = [] } = data || {};
    const pairs = documentMetadataPairs.map(pair => `${pair.title}`.trim());
    const uniquePairs = [...new Set(pairs)].map(pair => ({ id: pair, title: pair }));
    return Object.assign({ data: uniquePairs }, rest);
}
