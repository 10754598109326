import { t } from '@lingui/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { MultiSelectFilter } from './MultiSelectFilter';
export const entityTypeLabel = () => t({
    message: 'Typeof document',
    comment: 'Search entity type filter: Typeof document',
});
export function EntityTypeFilter() {
    const items = [
        {
            id: SearchEntityTypeEnum.DocumentComposed,
            title: t({
                message: 'Document (generated)',
                comment: 'Search entity type filter: Documents (generated)',
            }),
        },
        {
            id: SearchEntityTypeEnum.DocumentFreetext,
            title: t({
                message: 'Document (freetext)',
                comment: 'Search entity type filter: Document (freetext)',
            }),
        },
        {
            id: SearchEntityTypeEnum.DocumentUploaded,
            title: t({
                message: 'Document (uploaded)',
                comment: 'Search entity type filter: Document (uploaded)',
            }),
        },
    ];
    return <MultiSelectFilter name="filters.entityType" items={items} label={entityTypeLabel()}/>;
}
