import { useState } from 'react';
import { Box } from '@pocketlaw/tetris';
import { Count } from './Count';
import { CountChart } from './CountChart';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { getLabelForAllowedProperty } from '../../WidgetForm/SelectDataPoint/getAllowedProperties';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
export function BucketCount(props) {
    const { data, aggregation, colorScheme } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const field = getSelectedField(aggregation);
    const entries = data.buckets.filter(entry => entry.name !== TERMS_AGGREGATION_MISSING_VALUE_NAME);
    const totalCount = entries.reduce((acc, curr) => acc + curr.value, 0);
    const activeEntry = activeIndex != null && entries[activeIndex];
    const activeName = activeEntry ? activeEntry.name : '';
    const translatedLabel = getLabelForAllowedProperty(aggregation.type, field);
    const formattedValue = fieldDisplayValue(field, activeName);
    const activeLabel = activeEntry ? formattedValue : `${translatedLabel}`;
    return (<Box justifyContent="space-between" width="100%" alignItems="center" alignSelf="flex-start" gap="lg">
      <Count entries={entries} totalCount={totalCount} activeName={activeName} subtitle={activeLabel}/>
      <CountChart data={entries} dataKey="value" onHover={setActiveIndex} colorScheme={colorScheme}/>
    </Box>);
}
