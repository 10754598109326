import { MenuBody } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { SearchableMenuProvider, SearchableMenuSearchHeader, SearchableMenuSingleSelectList, } from 'shared/domains/common-ui';
import { NewValue } from './NewValue';
import { useGetPropertyValues } from './useGetPropertyValues';
import { SubmitButtonForSearchableLists } from '../SubmitButtonForSearchableLists';
export function SingleSelectWithInput(props) {
    const { name } = props;
    const [field, , helpers] = useField({
        name: 'value',
    });
    const { data } = useGetPropertyValues(name, field.value);
    const searchableItems = data.map(value => ({ id: value }));
    const handleSelectItem = (item) => {
        helpers.setValue(item);
    };
    return (<SearchableMenuProvider items={searchableItems} keys={['id']} fuseOptions={{
            threshold: 0,
        }}>
      <SearchableMenuSearchHeader />
      <MenuBody>
        <NewValue addItem={handleSelectItem}/>
        <SearchableMenuSingleSelectList onSelect={item => handleSelectItem(item.id)} renderItem={({ id }) => id} selected={field.value} emptyComponent={false}/>
      </MenuBody>
      <SubmitButtonForSearchableLists />
    </SearchableMenuProvider>);
}
