import { Box } from '@pocketlaw/tetris';
import { Count } from './Count';
import { allowedPropertiesTranslations, getMetricTypeTranslations, } from '../../WidgetForm/MetricInput/translations';
export function MetricCount(props) {
    const { data, aggregation } = props;
    const { metricInput } = aggregation || {};
    const { field, type } = metricInput || {};
    const value = Math.round((data === null || data === void 0 ? void 0 : data.value) || 0);
    const fieldTranslation = allowedPropertiesTranslations(field);
    const typeTranslation = getMetricTypeTranslations(type);
    return (<Box width="100%" alignSelf="flex-start">
      <Count totalCount={value} entries={[data]} subtitle={`${typeTranslation} ${fieldTranslation}`}/>
    </Box>);
}
