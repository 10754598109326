import { t } from '@lingui/macro';
import { Chip, ChipAction, MenuBody, Skeleton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SearchableMenu, SearchableMenuMultiSelectList } from 'shared/domains/common-ui';
import { useFilterDraft } from './useFilterDraft';
/**
 * Filter to use on SearchStringFilter metadata properties.
 */
export function MultiSelectFilter(props) {
    var _a, _b;
    const { items, label, loading = false, name } = props;
    const [field, , helpers] = useField(name);
    const draft = useFilterDraft();
    const selectedIds = ((_a = field.value) === null || _a === void 0 ? void 0 : _a.in) || [];
    const [firstSelection] = selectedIds;
    const secondaryCount = selectedIds.length > 1 ? `+${selectedIds.length - 1}` : '';
    const selectedTitle = (_b = items.find(item => item.id === firstSelection)) === null || _b === void 0 ? void 0 : _b.title;
    const secondaryText = selectedIds.length ? `${selectedTitle} ${secondaryCount}` : '';
    const showAction = Boolean(selectedIds.length);
    const initiallyOpen = draft.value === name;
    const shouldDisplay = Boolean(selectedIds.length) || draft.value === name;
    const handleReset = () => {
        draft.clear();
        helpers.setValue(undefined);
        helpers.setTouched(true);
    };
    const handleOnSelect = (item) => {
        const { id } = item;
        const selected = selectedIds.includes(id);
        helpers.setTouched(true);
        if (!selected) {
            helpers.setValue({ in: [...selectedIds, id] });
            return;
        }
        if (selectedIds.length === 1) {
            handleReset();
        }
        else {
            helpers.setValue({ in: selectedIds.filter(selectedItem => selectedItem !== id) });
        }
    };
    if (!shouldDisplay) {
        return null;
    }
    return (<Skeleton loading={loading}>
      <SearchableMenu items={items} keys={['title']} placeholder={t({
            message: 'Search',
            comment: 'Multi select filter search input placeholder',
        })} initiallyOpen={initiallyOpen && !loading} renderTarget={({ setOpen, open }) => (<Chip label={label} secondary={secondaryText} onClick={() => setOpen(!open)} size="large" appearance={selectedIds.length ? 'info' : 'secondary'} action={showAction && (<ChipAction onClick={handleReset} type="button">
                  <Falcon icon="remove"/>
                </ChipAction>)}/>)}>
        <MenuBody>
          <SearchableMenuMultiSelectList selected={selectedIds} renderItem={({ title }) => title} onSelect={handleOnSelect}/>
        </MenuBody>
      </SearchableMenu>
    </Skeleton>);
}
