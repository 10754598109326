var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Badge, Menu, MenuBody, MenuList, MenuItem, ConfirmDialog, Typography, ButtonGroup, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSetDocumentStatus } from 'app/domains/documents';
import { FileSystemDocumentStatusExplicitEvents } from 'shared/domains/apollo/generated/types';
import { useTableContext } from '../SearchResultsTable/TableContext';
import { useCreateActionEventHandlers } from '../SearchResultsTable/useCreateActionEventHandlers';
import { canChangeDocumentStatus } from '../SearchResultsTable/utils';
import { SelectedText } from './styled';
export function TableBulkActions() {
    const { activeAction, setActiveAction, selected, setSelected } = useTableContext();
    const [selectedStatus, setSelectedStatus] = useState();
    const { setStatus } = useSetDocumentStatus();
    const eventHandlers = useCreateActionEventHandlers('add-status');
    const eligibleDocuments = selected.filter(canChangeDocumentStatus);
    const eligibleDocumentsCount = eligibleDocuments.length;
    const handleShowConfirmDialog = (status) => {
        setActiveAction(undefined);
        setSelectedStatus(status);
    };
    const handleSetDocumentStatuses = () => __awaiter(this, void 0, void 0, function* () {
        if (!selectedStatus) {
            return;
        }
        const toUpdate = eligibleDocuments.map(result => ({
            event: selectedStatus,
            documentId: result.entityId,
            cacheId: result.id,
            // eslint-disable-next-line no-underscore-dangle
            typename: result.__typename || '',
        }));
        yield setStatus(toUpdate);
        setSelectedStatus(false);
        setSelected({ type: 'reset' });
    });
    const stateTranslations = {
        [FileSystemDocumentStatusExplicitEvents.ExplicitlySetAgreed]: t({
            message: 'agreed',
            comment: 'Bulk action dialog: Kind of document status selected',
        }),
        [FileSystemDocumentStatusExplicitEvents.ExplicitlySetSigned]: t({
            message: 'signed',
            comment: 'Bulk action dialog: Kind of document status selected',
        }),
        [FileSystemDocumentStatusExplicitEvents.ExplicitlySetDraft]: t({
            message: 'draft',
            comment: 'Bulk action dialog: Kind of document status selected',
        }),
    };
    const translatedStatus = selectedStatus ? stateTranslations[selectedStatus] : undefined;
    const menuOpen = (activeAction === null || activeAction === void 0 ? void 0 : activeAction.name) === 'add-status' && (activeAction === null || activeAction === void 0 ? void 0 : activeAction.event) === 'open';
    const selectedCount = selected.length;
    return (<ButtonGroup>
      <SelectedText disabled={selectedCount === 0}>
        <Trans comment="Bulk action: Selected count">{selectedCount} selected</Trans>
      </SelectedText>
      {selectedStatus && (<ConfirmDialog appearance="primary" size="sm" confirm="Confirm" onConfirm={handleSetDocumentStatuses} cancel="Cancel" onCancel={() => setSelectedStatus(false)} heading={t({ message: 'Confirm', comment: 'Bulk action: Confirm dialog heading' })}>
          <Typography>
            <Trans comment="Bulk action: Confirm dialog text">
              Are you sure you want to change the status to {translatedStatus} for the selected
              document(s)?
            </Trans>
          </Typography>
        </ConfirmDialog>)}
      <Menu target={<Button appearance="secondary" size="small" disabled={!eligibleDocumentsCount} {...eventHandlers}>
            <Falcon icon="plus"/>
            <Trans comment="Bulk action: Add status">Add status</Trans>
            {eligibleDocumentsCount > 0 && <Badge value={eligibleDocumentsCount} size="small"/>}
          </Button>} open={menuOpen} onClose={() => setActiveAction(undefined)}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={() => handleShowConfirmDialog(FileSystemDocumentStatusExplicitEvents.ExplicitlySetAgreed)}>
              <Trans comment="Bulk action: Set document status">Set as agreed</Trans>
            </MenuItem>
            <MenuItem onClick={() => handleShowConfirmDialog(FileSystemDocumentStatusExplicitEvents.ExplicitlySetSigned)}>
              <Trans comment="Bulk action: Set document status">Set as signed</Trans>
            </MenuItem>
            <MenuItem onClick={() => handleShowConfirmDialog(FileSystemDocumentStatusExplicitEvents.ExplicitlySetDraft)}>
              <Trans comment="Bulk action: Set document status">Set as draft</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
    </ButtonGroup>);
}
