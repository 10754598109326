import { t } from '@lingui/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { ALL_DOCUMENTS } from './constants';
import { useMultiselectFilter, MultiSelectFilter } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function EntityTypeFilter() {
    const filter = useMultiselectFilter({
        items: [
            {
                id: SearchEntityTypeEnum.Template,
                title: t({ message: 'Template', comment: 'Search entity type filter: Template' }),
            },
            {
                id: SearchEntityTypeEnum.Draft,
                title: t({ message: 'Draft', comment: 'Search entity type filter: Draft' }),
            },
            {
                id: ALL_DOCUMENTS,
                title: t({
                    message: 'Documents (all types)',
                    comment: 'Search entity type filter: Documents (all types)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentComposed,
                title: t({
                    message: 'Document (generated)',
                    comment: 'Search entity type filter: Documents (generated)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentFreetext,
                title: t({
                    message: 'Document (freetext)',
                    comment: 'Search entity type filter: Document (freetext)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentUploaded,
                title: t({
                    message: 'Document (uploaded)',
                    comment: 'Search entity type filter: Document (uploaded)',
                }),
            },
            {
                id: SearchEntityTypeEnum.Folder,
                title: t({ message: 'Folder', comment: 'Search entity type filter: Folder' }),
            },
            {
                id: SearchEntityTypeEnum.User,
                title: t({ message: 'User', comment: 'Search entity type filter: User' }),
            },
        ],
        paramKey: 'type',
        name: searchFilterCopy().type,
    });
    return <MultiSelectFilter filter={filter}/>;
}
