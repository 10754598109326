export const getDateLabelType = (createdAt, updatedAt) => {
    if (!createdAt && !updatedAt) {
        return 'new';
    }
    if (createdAt && !updatedAt) {
        return 'created';
    }
    if (updatedAt && !createdAt) {
        return 'updated';
    }
    if (createdAt === updatedAt) {
        return 'created';
    }
    return 'updated';
};
