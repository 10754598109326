import { useRef } from 'react';
import { t } from '@lingui/macro';
import { Box, ChipAction, TextInput } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ChipDropdown } from 'shared/domains/common-ui';
import { useFilterDraft } from './useFilterDraft';
export function NumberRangeFilter(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { name, label } = props;
    const [field, , helpers] = useField({
        name,
    });
    const dropdownRef = useRef();
    const draft = useFilterDraft();
    const hasValue = Boolean(((_a = field.value) === null || _a === void 0 ? void 0 : _a.gte) || ((_b = field.value) === null || _b === void 0 ? void 0 : _b.lte));
    const isDraft = draft.value === name;
    const shouldDisplay = hasValue || isDraft;
    const secondary = `
    ${((_c = field.value) === null || _c === void 0 ? void 0 : _c.gte) || ''}
    ${((_d = field.value) === null || _d === void 0 ? void 0 : _d.gte) && ((_e = field.value) === null || _e === void 0 ? void 0 : _e.lte) ? ' - ' : ''}
    ${((_f = field.value) === null || _f === void 0 ? void 0 : _f.lte) || ''}
  `;
    const handleRemoveValue = () => {
        draft.clear();
        helpers.setValue(undefined);
        helpers.setTouched(true);
    };
    const handleSetField = (key) => (ev) => {
        const { value } = ev.target;
        const numberValue = parseInt(value, 10);
        helpers.setTouched(true);
        helpers.setValue(Object.assign(Object.assign({}, (field.value || {})), { [key]: numberValue }));
    };
    if (!shouldDisplay) {
        return null;
    }
    return (<ChipDropdown ref={dropdownRef} label={label} secondary={secondary} initiallyOpen={isDraft} action={hasValue && (<ChipAction onClick={handleRemoveValue}>
            <Falcon icon="remove"/>
          </ChipAction>)} dropdownContent={<Box flexDirection="column" p="md" gap="md">
          <TextInput label={t({ message: 'From', comment: 'Insights: Number range filter: From' })} type="number" value={((_g = field.value) === null || _g === void 0 ? void 0 : _g.gte) || ''} onChange={handleSetField('gte')}/>
          <TextInput label={t({ message: 'To', comment: 'Insights: Number range filter: To' })} type="number" value={((_h = field.value) === null || _h === void 0 ? void 0 : _h.lte) || ''} onChange={handleSetField('lte')}/>
        </Box>}/>);
}
