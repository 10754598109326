import { createContext, useState } from 'react';
import { t } from '@lingui/macro';
import { ROOT, useFileBrowserContext } from 'app/domains/filesystem';
export const PickerContext = createContext(null);
export function PickerProvider(props) {
    const { children, onClose, onSubmit } = props;
    const { selected } = useFileBrowserContext();
    const [showRootWarning, setShowRootWarning] = useState(false);
    const isRootSelected = !(selected === null || selected === void 0 ? void 0 : selected.id) || selected.id === ROOT;
    const handleSubmit = () => {
        if (!showRootWarning && isRootSelected) {
            setShowRootWarning(true);
        }
        else {
            if (selected) {
                onSubmit({ id: selected.id, name: selected.name });
            }
            else {
                onSubmit({
                    id: ROOT,
                    name: t({
                        message: 'Root',
                        comment: 'Root folder label',
                    }),
                });
            }
            onClose();
        }
    };
    const hideRootWarning = () => setShowRootWarning(false);
    const value = {
        showRootWarning,
        hideRootWarning,
        submit: handleSubmit,
    };
    return <PickerContext.Provider value={value}>{children}</PickerContext.Provider>;
}
