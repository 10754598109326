import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { getDocumentStatusTranslations } from 'app/domains/documents';
import { FileSystemDocumentStatus } from 'shared/domains/apollo/generated/types';
import { MultiSelectFilter } from './MultiSelectFilter';
export const documentStatusLabel = () => t({ message: 'Document status', comment: 'Insights filter: Document category' });
export function DocumentStatus() {
    const lingui = useLingui();
    const items = [
        FileSystemDocumentStatus.Unknown,
        FileSystemDocumentStatus.DraftStage,
        FileSystemDocumentStatus.ReviewStagePending,
        FileSystemDocumentStatus.AgreedStageAgreedForm,
        FileSystemDocumentStatus.SigningStagePendingNotYetSigned,
        FileSystemDocumentStatus.SigningStagePendingPartiallySigned,
        FileSystemDocumentStatus.SigningStageSigned,
    ].map(status => ({
        id: status,
        title: lingui._(getDocumentStatusTranslations(status)),
    }));
    return (<MultiSelectFilter name="filters.metadataDocumentStatus" items={items} label={documentStatusLabel()}/>);
}
