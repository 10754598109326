var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { PowerInsightsDocument } from './PowerInsights.gql';
import { dateHistogramTypeGuard, dateRangeTypeGuard } from '../utils';
export function useGetData(variables) {
    var _a;
    const { filters, aggregations } = variables;
    const _b = filters || {}, { entityType = {}, metadataDocumentEsigningStatus = {} } = _b, localFilters = __rest(_b, ["entityType", "metadataDocumentEsigningStatus"]);
    const { data, previousData, error, loading } = useQuery(PowerInsightsDocument, {
        // NOTE: If we load from cache, we have problems with apollo not being able to
        // differentiate between aggregations sharing ID's
        fetchPolicy: 'no-cache',
        variables: {
            filters: Object.assign(Object.assign({}, localFilters), { entityType: Object.assign(Object.assign({}, entityType), { notIn: [
                        SearchEntityTypeEnum.Draft,
                        SearchEntityTypeEnum.Template,
                        SearchEntityTypeEnum.User,
                        SearchEntityTypeEnum.Folder,
                    ] }), metadataDocumentEsigningStatus }),
            aggregations: aggregations || [],
        },
    });
    const { search } = data || {};
    const { search: previousSearch } = previousData || {};
    const mergedData = search || previousSearch;
    const dataPoints = (_a = mergedData === null || mergedData === void 0 ? void 0 : mergedData.aggregations) === null || _a === void 0 ? void 0 : _a.map(aggregation => {
        if (dateHistogramTypeGuard(aggregation) || dateRangeTypeGuard(aggregation)) {
            const { buckets } = aggregation || {};
            return Object.assign(Object.assign({}, aggregation), { buckets: buckets === null || buckets === void 0 ? void 0 : buckets.sort((a, b) => new Date(a.name).valueOf() - new Date(b.name).valueOf()) });
        }
        return aggregation;
    });
    const { totalHitsCount = 0 } = mergedData || {};
    return {
        data: {
            totalHitsCount,
            dataPoints,
        },
        error: Boolean(error),
        loading,
    };
}
