import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, ChipAction, MenuBody, MenuDivider, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { parseISO, sub } from 'date-fns';
import { useField } from 'formik';
import { DateRangeForm, } from 'app/domains/global-search/components/DateRangeFilter/DateRangeForm';
import { ChipDropdown } from 'shared/domains/common-ui';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { useFilterDraft } from './useFilterDraft';
export function DateRangeFilter(props) {
    const { name, label } = props;
    const [displayDateRangeForm, setDisplayDateRangeForm] = useState(false);
    const [field, , helpers] = useField(name);
    const draft = useFilterDraft();
    const data = field.value;
    const toggleDateRangeForm = () => setDisplayDateRangeForm(!displayDateRangeForm);
    const handleOnSubmit = (newDateRange) => {
        var _a, _b;
        helpers.setTouched(true);
        helpers.setValue({
            gte: (_a = newDateRange.startDate) === null || _a === void 0 ? void 0 : _a.toISOString(),
            lte: (_b = newDateRange.endDate) === null || _b === void 0 ? void 0 : _b.toISOString(),
        });
        draft.clear();
    };
    const monthPreset = (months) => {
        const newDate = {
            startDate: sub(new Date(), { months }),
            endDate: new Date(),
        };
        handleOnSubmit(newDate);
    };
    const removeValue = () => {
        draft.clear();
        helpers.setValue(null);
    };
    const startDate = (data === null || data === void 0 ? void 0 : data.gte) ? parseISO(data === null || data === void 0 ? void 0 : data.gte) : undefined;
    const endDate = (data === null || data === void 0 ? void 0 : data.lte) ? parseISO(data === null || data === void 0 ? void 0 : data.lte) : undefined;
    const localizedStartDate = (startDate && localizedFormatDate(startDate.toISOString())) || '';
    const localizedEndDate = (endDate && localizedFormatDate(endDate.toISOString())) || '';
    const separator = (localizedStartDate && localizedEndDate && ' - ') || '';
    const hasValue = Boolean((data === null || data === void 0 ? void 0 : data.gte) || (data === null || data === void 0 ? void 0 : data.lte));
    const isDraft = draft.value === name;
    const shouldDisplay = hasValue || isDraft;
    if (!shouldDisplay) {
        return null;
    }
    return (<ChipDropdown initiallyOpen={isDraft} label={label} appearance={hasValue ? 'info' : 'secondary'} secondary={`${localizedStartDate}${separator}${localizedEndDate}`} dropdownContent={displayDateRangeForm ? (<MenuBody>
            <DateRangeForm initialValue={{
                startDate,
                endDate,
            }} onSubmit={handleOnSubmit}/>
            <MenuList>
              <MenuDivider />
              <MenuItem onClick={toggleDateRangeForm}>
                <Falcon icon="chevron-left"/>
                <Trans comment="Date range filter - Relative date trigger">Relative date</Trans>
              </MenuItem>
            </MenuList>
          </MenuBody>) : (<MenuBody>
            <MenuList>
              <MenuItem onClick={() => monthPreset(1)}>
                <Trans comment="Date range filter - Last 30 days">Last 30 days</Trans>
              </MenuItem>
              <MenuItem onClick={() => monthPreset(2)}>
                <Trans comment="Date range filter - Last 60 days">Last 60 days</Trans>
              </MenuItem>
              <MenuItem onClick={() => monthPreset(3)}>
                <Trans comment="Date range filter - Last 90 days">Last 90 days</Trans>
              </MenuItem>
              <MenuItem onClick={() => monthPreset(6)}>
                <Trans comment="Date range filter - Last half year">Half a year</Trans>
              </MenuItem>
              <MenuItem onClick={() => monthPreset(12)}>
                <Trans comment="Date range filter - Last year">One year</Trans>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={toggleDateRangeForm}>
                <Trans comment="Date range filter - Absolute date trigger">Absolute date</Trans>
                <Box ml="auto">
                  <Falcon icon="calendar"/>
                </Box>
              </MenuItem>
            </MenuList>
          </MenuBody>)} action={hasValue && (<ChipAction onClick={removeValue}>
            <Falcon icon="remove"/>
          </ChipAction>)}/>);
}
