import { v4 as uuid } from 'uuid';
import { SearchAggregationTimeUnit, SearchAggregationType, } from 'shared/domains/apollo/generated/types';
export const getDefaultDateRangeValue = (field) => ({
    type: SearchAggregationType.DateRange,
    name: uuid(),
    dateRangeInput: {
        field,
        ranges: [
            {
                fromNow: true,
                fromOffset: {
                    value: 0,
                    timeUnit: SearchAggregationTimeUnit.D,
                    roundingTimeUnit: SearchAggregationTimeUnit.D,
                },
                toNow: true,
                toOffset: {
                    value: 31,
                    timeUnit: SearchAggregationTimeUnit.D,
                    roundingTimeUnit: SearchAggregationTimeUnit.D,
                },
            },
            {
                fromNow: true,
                toNow: true,
                fromOffset: {
                    value: 31,
                    timeUnit: SearchAggregationTimeUnit.D,
                    roundingTimeUnit: SearchAggregationTimeUnit.D,
                },
                toOffset: {
                    value: 61,
                    timeUnit: SearchAggregationTimeUnit.D,
                    roundingTimeUnit: SearchAggregationTimeUnit.D,
                },
            },
            {
                fromNow: true,
                toNow: true,
                fromOffset: {
                    value: 61,
                    timeUnit: SearchAggregationTimeUnit.D,
                    roundingTimeUnit: SearchAggregationTimeUnit.D,
                },
                toOffset: {
                    value: 91,
                    timeUnit: SearchAggregationTimeUnit.Day,
                    roundingTimeUnit: SearchAggregationTimeUnit.D,
                },
            },
        ],
    },
});
