import { useLayoutEffect } from 'react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { TableColumnToggle } from '@pocketlaw/tetris';
import { useSearchContext } from '../SearchProvider';
export function SearchTableColumnToggle() {
    const { setHiddenColRows, columnConfigs } = useSearchContext();
    const lingui = useLingui();
    useLayoutEffect(() => {
        const initiallyHiddenColRows = columnConfigs
            .filter(config => !config.active)
            .map(config => config.id);
        setHiddenColRows(initiallyHiddenColRows);
    }, [columnConfigs, setHiddenColRows]);
    const handleColumnToggle = (colRows) => {
        const hiddenRows = colRows.filter(item => !item.active).map(item => item.id);
        setHiddenColRows(hiddenRows);
    };
    const headers = columnConfigs
        .filter(config => !config.disabled)
        .map(header => (Object.assign(Object.assign({}, header), { label: lingui._(header.label) })));
    return (<TableColumnToggle size="small" headers={headers} onChange={handleColumnToggle} appearance="ghost">
      {t({
            message: 'Columns',
            comment: 'Search table column toggle button label',
        })}
    </TableColumnToggle>);
}
