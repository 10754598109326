var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { SelectWithInputDocument } from './SelectWithInput.gql';
export function useGetPropertyValues(name, existing) {
    const _a = useQuery(SelectWithInputDocument, {
        skip: !name,
        variables: {
            name,
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { documentMetadataPairs = [] } = data || {};
    const pairs = documentMetadataPairs.flatMap(({ value }) => value);
    const existingValues = Array.isArray(existing) ? existing : [existing];
    const mergedData = [...pairs, ...existingValues].map(str => (str || '').trim());
    const uniquePairs = [...new Set([...mergedData])].filter(value => value !== '');
    return Object.assign({ data: uniquePairs }, rest);
}
