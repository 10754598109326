import { InMemoryCache } from '@apollo/client';
import * as metadata from 'shared/domains/metadata/cache/typePolicies';
import { activeAccount } from 'shared/domains/users/utils/activeAccount';
import { findLocalizedMetadata } from './findLocalizedMetadata';
import { mergeArrayByField } from './mergeArrayByField';
import schemaTypes from './possibleTypes.json';
import { authenticatedVar } from '../utils/cacheVars';
const { possibleTypes } = schemaTypes;
export function createCache() {
    const cache = new InMemoryCache({
        possibleTypes,
        typePolicies: {
            Query: {
                fields: Object.assign({ assistant(existing) {
                        return existing !== null && existing !== void 0 ? existing : null;
                    },
                    touchedEntries(existing) {
                        return existing !== null && existing !== void 0 ? existing : [];
                    },
                    account(existing) {
                        return existing !== null && existing !== void 0 ? existing : null;
                    }, accounts: {
                        read(existing) {
                            return existing !== null && existing !== void 0 ? existing : [];
                        },
                        merge: mergeArrayByField('id'),
                    }, profile(existing) {
                        return existing !== null && existing !== void 0 ? existing : null;
                    }, authenticated: {
                        read() {
                            return authenticatedVar();
                        },
                        merge(_, incoming) {
                            return authenticatedVar(incoming);
                        },
                    }, storedAccountId() {
                        return activeAccount.get();
                    },
                    market(_, { args, toReference }) {
                        return toReference({
                            __typename: 'Market',
                            code: args === null || args === void 0 ? void 0 : args.code,
                        });
                    },
                    company(_, { args, toReference }) {
                        return toReference({
                            __typename: 'Company',
                            id: args === null || args === void 0 ? void 0 : args.id,
                        });
                    },
                    composer(_, { args, toReference }) {
                        return toReference({
                            __typename: 'Composer',
                            id: args === null || args === void 0 ? void 0 : args.id,
                        });
                    }, search: {
                        keyArgs: ['query', 'limit', 'where', 'modifiers', 'aggregations'],
                        read(existing) {
                            return existing;
                        },
                    }, fileSystemDocument: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'FileSystemDocument',
                                id: args === null || args === void 0 ? void 0 : args.id,
                            });
                        },
                    } }, metadata.typePolicies.Query.fields),
            },
            WebApiClient: {
                fields: {
                    clientSecret: {
                        merge() {
                            // This field is highly sensitive. The client should not be able to read it from the cache.
                            return null;
                        },
                    },
                },
            },
            Category: {
                fields: {
                    metadata: {
                        read: findLocalizedMetadata,
                    },
                },
            },
            Composer: {
                fields: {
                    metadata: {
                        read: findLocalizedMetadata,
                    },
                },
            },
            Unstable__Composer: {
                fields: {
                    metadata: {
                        read: findLocalizedMetadata,
                    },
                },
            },
            Feature: {
                fields: {
                    metadata: {
                        read: findLocalizedMetadata,
                    },
                },
            },
            Task: {
                fields: {
                    reminders: {
                        merge: (_, incoming) => incoming,
                    },
                },
            },
            Company: {
                fields: {
                    plans: {
                        keyArgs: false,
                    },
                    esigning_providers: {
                        merge: (_, incoming) => incoming,
                    },
                },
            },
            DocumentChat: {
                fields: {
                    items: {
                        keyArgs: false,
                        merge(existing = [], incoming) {
                            /* eslint-disable no-underscore-dangle */
                            const existingRefs = new Set(existing.map((value) => value.__ref));
                            const newIncoming = incoming.filter((value) => !existingRefs.has(value.__ref));
                            // remove the temporary document chat message otherwise we will get an annoying flickering
                            // in the chat thread before they are properly removed from the cache.
                            const filteredExisting = existing.filter((value) => {
                                // we could use the actual id defined in the "document assistant" but I don't want
                                // to take a dependency on that code.
                                const uuidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                                const [, id] = value.__ref.split(':');
                                return uuidPattern.test(id);
                            });
                            // since we are fetching the message in descending order we need to add these first
                            // or they will be out-of-order.
                            return [...newIncoming, ...filteredExisting];
                            /* eslint-enable no-underscore-dangle */
                        },
                    },
                },
            },
            Approval: {
                fields: {
                    approvers: {
                        merge: (_, incoming) => incoming,
                    },
                },
            },
            DocumentAssistantPrompt: {
                fields: {
                    isSystemPrompt: {
                        read() {
                            return false;
                        },
                    },
                },
            },
            Market: {
                keyFields: ['code'],
            },
            MarketLocale: {
                keyFields: ['locale'],
            },
            Profile: {
                keyFields: ['userId'],
            },
            Tree: {
                keyFields: ['id', 'version'],
            },
            TemplateVersion: {
                keyFields: ['reference', 'template_id'],
            },
            Unstable__TemplateVersion: {
                keyFields: ['reference', 'templateId'],
            },
        },
    });
    return cache;
}
export default createCache();
