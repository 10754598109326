import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, IconButton, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { ConfirmRemoveDialog } from './ConfirmRemoveDialog';
import { EditDialog } from './EditDialog';
import { getDateLabelType } from './utils';
import { useAttachments } from '../Provider';
import { Container } from './styled';
export function Item(props) {
    const { name, path, createdAt, updatedAt } = props;
    const { disabled, removeAttachment, updateAttachmentName } = useAttachments();
    const [renderMenu, setRenderMenu] = useState(false);
    const [renderEditDialog, setRenderEditDialog] = useState(false);
    const [renderRemoveDialog, setRenderRemoveDialog] = useState(false);
    const dateLabelType = getDateLabelType(createdAt, updatedAt);
    const createdAtDate = localizedFormatDate(createdAt, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
    const updatedAtDate = localizedFormatDate(updatedAt, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
    const createdAtLabel = t({
        comment: 'Attachment added label',
        message: `Added ${createdAtDate}`,
    });
    const updatedAtLabel = t({
        comment: 'Attachment updated label',
        message: `Updated ${updatedAtDate}`,
    });
    const newAttachmentLabel = t({ comment: 'Attachment added label', message: 'New attachment' });
    const map = {
        created: createdAtLabel,
        updated: updatedAtLabel,
        new: newAttachmentLabel,
    };
    const subtitle = map[dateLabelType];
    const showMenu = () => setRenderMenu(true);
    const hideMenu = () => setRenderMenu(false);
    const hideEditDialog = () => setRenderEditDialog(false);
    const hideRemoveDialog = () => setRenderRemoveDialog(false);
    const showEditDialog = () => {
        setRenderEditDialog(true);
        hideMenu();
    };
    const showRemoveDialog = () => {
        setRenderRemoveDialog(true);
        hideMenu();
    };
    const handleRemoveAttachment = () => {
        removeAttachment(path);
    };
    const handleRenameAttachment = (newName) => {
        updateAttachmentName(path, newName);
    };
    return (<Fragment>
      {renderRemoveDialog && (<ConfirmRemoveDialog onCancel={hideRemoveDialog} onConfirm={handleRemoveAttachment}/>)}
      {renderEditDialog && (<EditDialog initialName={name} onClose={hideEditDialog} onEdit={handleRenameAttachment}/>)}
      <Container>
        <AvatarWithLabel solid size="sm" appearance="info" title={name} subtitle={subtitle}>
          <Falcon icon="paperclip"/>
        </AvatarWithLabel>
        <Menu open={renderMenu} onClose={hideMenu} target={<IconButton $round size="xs" appearance="ghost" disabled={disabled} onClick={showMenu}>
              <Falcon icon="ellipsis-v"/>
            </IconButton>}>
          <MenuBody>
            <MenuList>
              <MenuItem onClick={showEditDialog}>
                <Falcon icon="pencil"/>
                <Trans comment="Menu label for editing attachment name">Edit name</Trans>
              </MenuItem>
              <MenuItem onClick={showRemoveDialog}>
                <Falcon icon="xmark"/>
                <Trans comment="Menu label for removing attachment">Remove</Trans>
              </MenuItem>
            </MenuList>
          </MenuBody>
        </Menu>
      </Container>
    </Fragment>);
}
