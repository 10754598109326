import { Chip, ChipAction, MenuBody, Skeleton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchableMenu, SearchableMenuMultiSelectList } from 'shared/domains/common-ui';
export function MultiSelectFilter(props) {
    var _a;
    const { filter } = props;
    const handleSelect = (selectedItem) => {
        var _a;
        const isSelected = (_a = filter.currentItems) === null || _a === void 0 ? void 0 : _a.some(item => item.id === selectedItem.id);
        if (isSelected) {
            filter.remove(selectedItem);
        }
        else {
            filter.add(selectedItem);
        }
        if (isSelected && filter.currentItems.length === 1) {
            filter.clear();
        }
    };
    const selected = ((_a = filter.currentItems) === null || _a === void 0 ? void 0 : _a.map(item => item.id)) || [];
    if (!filter.isActive && !filter.isInDraft)
        return null;
    return (<Skeleton loading={filter.loading}>
      <SearchableMenu items={filter.items} keys={['title']} initiallyOpen={filter.isInDraft && !filter.loading} renderTarget={({ setOpen, open }) => (<Chip label={filter.name} secondary={filter.value} onClick={() => setOpen(!open)} size="large" appearance={selected.length ? 'info' : 'secondary'} action={Boolean(selected.length) && (<ChipAction onClick={filter.clear} type="button">
                  <Falcon icon="remove"/>
                </ChipAction>)}/>)}>
        <MenuBody>
          <SearchableMenuMultiSelectList selected={selected} renderItem={({ title }) => title} onSelect={item => handleSelect(item)}/>
        </MenuBody>
      </SearchableMenu>
    </Skeleton>);
}
