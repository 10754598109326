import { Box, pxToRem, themeGet, themeGetColor, scrollbar, Typography } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const WidgetContainer = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  border: ${pxToRem(1)} solid ${themeGetColor('border.100')};
  border-radius: ${themeGet('borderRadius.lg')};
  background-color: ${themeGetColor('background.50')};

  overflow: hidden;

  transition: all 0.2s ease-in-out;

  ${props => props.$editMode &&
    `
    &:hover {
      border-color: ${themeGetColor('border.200')};
      box-shadow: 0 0 0 ${pxToRem(2)} ${themeGetColor('border.100', 0.75)};
    }  
  `}
`;
export const WidgetHeader = styled(Box) `
  background-color: ${themeGetColor('background.50')};
  z-index: 1;
`;
export const WidgetBody = styled(Box) `
  height: 100%;
  overflow: hidden auto;
  ${scrollbar({ trackMargin: 'md' })}
`;
export const WidgetDescription = styled(Typography) `
  margin-top: ${themeGet('spacing.sm')};
`;
