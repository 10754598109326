import { Box, Typography } from '@pocketlaw/tetris';
export function TooltipContent(props) {
    const { active, payload, label } = props;
    const display = active && payload && payload.length;
    if (!display) {
        return null;
    }
    const hasBreakdown = payload.length > 1;
    return (<Box bg="primary.900" borderRadius="md" pt="md" pb="md" pl="lg" pr="lg" gap="md" flexDirection="column">
      <Typography $fontSize="medium" $fontWeight="bold" $appearance="light">
        {label}: {!hasBreakdown && payload[0].value}
      </Typography>

      {hasBreakdown &&
            payload.map(item => (<Typography key={item.name} $appearance="light">
            {item.name}: {item.value}
          </Typography>))}
    </Box>);
}
