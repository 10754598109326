import { Fragment, useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { t } from '@lingui/macro';
import { LoaderOverlay } from '@pocketlaw/tetris';
import { DecoupledEditor } from 'pleditor';
import * as Sentry from 'shared/domains/sentry';
import { DocumentFlushModal } from './DocumentFlushModal';
import { DocumentFlushModalExternal } from './DocumentFlushModalExternal';
import { RevisionHistoryEditor } from './RevisionHistoryEditor';
import { usePleditor } from '../../PleditorProvider';
import { CompanyLogo } from '../../components/CompanyLogo';
import { CriticalError } from '../../components/CriticalError';
import { FeatureAvailability } from '../../components/FeatureAvailability';
import { documentFlush } from '../../documentFlush';
import { documentState } from '../../documentState';
import { useIsLayoutReady } from '../../hooks/useIsLayoutReady';
import { useToastHandler } from '../../hooks/useToastHandler';
import { ComposedHighlightStyles } from '../../plugins/ComposedHighlightToolbar';
import { AppPleditorStyles, BasePleditorStyles, ExternalPleditorStyles, PleditorContainer, PleditorSidebar, PleditorWrapper, } from '../../styled';
import { useConfig } from '../hooks';
export function RTCPleditor(props) {
    const { documentType, plugins, toolbarItems, balloonToolbarItems, currentUser, featureAvailability, usersExternal, usersWithAccess, usersWithoutAccess, isSessionExpired, locked = false, } = props;
    const { setPleditor, unsetPleditor } = usePleditor();
    const flush = documentFlush();
    const isLayoutReady = useIsLayoutReady();
    const isExternal = process.env.WEB_CLIENT === 'external';
    const [editorLoaded, setEditorLoaded] = useState(false);
    const editorRef = useRef(null);
    const commentSidebarRef = useRef(null);
    const editorContainerRef = useRef(null);
    const viewerContainerRef = useRef(null);
    const viewerEditorElementRef = useRef(null);
    const viewerSidebarContainerRef = useRef(null);
    useToastHandler();
    const handleEditorReady = (editor) => {
        editorRef.current = editor;
        setEditorLoaded(true);
        setPleditor(editor, locked);
    };
    const handleAfterDestroy = () => {
        unsetPleditor();
    };
    const checkPendingActions = (event) => {
        const editor = editorRef.current;
        if (!editor) {
            return;
        }
        if (editor.plugins.get('PendingActions').hasAny) {
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = true;
        }
    };
    useEffect(() => {
        window.addEventListener('beforeunload', checkPendingActions);
        return () => {
            window.removeEventListener('beforeunload', checkPendingActions);
        };
    }, []);
    useEffect(() => {
        var _a;
        if (isSessionExpired) {
            (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
        }
    }, [isSessionExpired]);
    const editorConfig = useConfig({
        documentType,
        plugins,
        toolbarItems,
        balloonToolbarItems,
        featureAvailability,
        currentUser,
        refs: {
            editorContainerRef: editorContainerRef.current,
            viewerContainerRef: viewerContainerRef.current,
            viewerEditorElementRef: viewerEditorElementRef.current,
            viewerSidebarContainerRef: viewerSidebarContainerRef.current,
            sidebarContainerRef: commentSidebarRef.current,
        },
    });
    return (<LoaderOverlay enabled={!editorLoaded || flush.state === 'loading'} label={t({
            comment: 'Loading real time collaboration plugins for a document',
            message: 'Loading document',
        })}>
      <FeatureAvailability />
      <BasePleditorStyles />
      <ComposedHighlightStyles />
      {isExternal ? (<ExternalPleditorStyles />) : (<AppPleditorStyles $usersExternal={usersExternal} $usersWithoutAccess={usersWithoutAccess} $usersWithAccess={usersWithAccess}/>)}
      <CriticalError />
      {isExternal ? <DocumentFlushModalExternal /> : <DocumentFlushModal />}
      {/* Editor view */}
      <PleditorWrapper ref={editorContainerRef}>
        <PleditorContainer>
          {isLayoutReady && (<Fragment>
              <CompanyLogo sidebar={commentSidebarRef.current} container={editorContainerRef.current} editorLoaded={editorLoaded}/>
              <CKEditor key={`CKEditor-${locked}`} // This is so the editor is re-rendered when the document is locked/unlocked
         editor={DecoupledEditor} config={editorConfig} onReady={handleEditorReady} onAfterDestroy={handleAfterDestroy} onError={(error, details) => {
                var _a, _b;
                Sentry.captureException(error, { tags: { feature: 'pleditor' } });
                documentState('error');
                if (details === null || details === void 0 ? void 0 : details.willEditorRestart) {
                    (_b = (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.ui.view.toolbar.element) === null || _b === void 0 ? void 0 : _b.remove();
                }
            }}/>
            </Fragment>)}
          <PleditorSidebar ref={commentSidebarRef}/>
        </PleditorContainer>
      </PleditorWrapper>
      {/* Revision history editor view */}
      <RevisionHistoryEditor containerRef={viewerContainerRef} editorRef={viewerEditorElementRef} sidebarRef={viewerSidebarContainerRef}/>
    </LoaderOverlay>);
}
