import { useState } from 'react';
import { t } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { Count } from './Count';
import { CountChart } from './CountChart';
import { getLabelForAllowedProperty } from '../../WidgetForm/SelectDataPoint/getAllowedProperties';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
export function DateRangeCount(props) {
    const { data, aggregation, colorScheme } = props;
    const [activeIndex, setActiveIndex] = useState(null);
    const entries = data.buckets;
    const field = getSelectedField(aggregation);
    const totalCount = entries.reduce((acc, curr) => acc + curr.value, 0);
    const activeEntry = activeIndex != null && entries[activeIndex];
    const activeName = activeEntry ? activeEntry.name : '';
    const { from, to } = activeEntry || {};
    const formattedFrom = fieldDisplayValue(field, from);
    const formattedTo = fieldDisplayValue(field, to);
    const translatedLabel = getLabelForAllowedProperty(aggregation.type, field);
    const activeLabel = activeEntry
        ? t({
            message: `From ${formattedFrom} to ${formattedTo}`,
            comment: 'Insights - Date range count label',
        })
        : `${translatedLabel}`;
    return (<Box justifyContent="space-between" width="100%" alignItems="center" alignSelf="flex-start" gap="lg">
      <Count entries={entries} activeName={activeName} totalCount={totalCount} subtitle={activeLabel}/>
      <CountChart data={entries} dataKey="value" onHover={setActiveIndex} colorScheme={colorScheme}/>
    </Box>);
}
