import { t } from '@lingui/macro';
import { Chip, ChipAction, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SearchableMenu, SearchableMenuSingleSelectList } from 'shared/domains/common-ui';
import { useFilterDraft } from './useFilterDraft';
export function SingleSelectFilter(props) {
    var _a;
    const { items, selectedId = '', label, onChange, onReset, initiallyOpen = false, name } = props;
    const [, , helpers] = useField(name);
    const draft = useFilterDraft();
    const selectedTitle = (_a = items.find(item => item.id === selectedId)) === null || _a === void 0 ? void 0 : _a.title;
    const shouldDisplay = Boolean(selectedId) || draft.value === name;
    const handleOnChange = (item) => {
        helpers.setTouched(true);
        onChange(item);
    };
    const handleOnReset = () => {
        helpers.setTouched(true);
        draft.clear();
        onReset();
    };
    if (!shouldDisplay) {
        return null;
    }
    return (<SearchableMenu items={items} keys={['title']} placeholder={t({ message: 'Search', comment: 'Single select search input placeholder' })} initiallyOpen={initiallyOpen} renderTarget={({ setOpen, open }) => (<Chip label={label} secondary={selectedTitle} onClick={() => setOpen(!open)} size="large" appearance={selectedId ? 'info' : 'secondary'} action={selectedId && (<ChipAction onClick={handleOnReset} type="button">
                <Falcon icon="remove"/>
              </ChipAction>)}/>)}>
      <MenuBody>
        <SearchableMenuSingleSelectList selected={selectedId} renderItem={({ title }) => title} onSelect={handleOnChange}/>
      </MenuBody>
    </SearchableMenu>);
}
