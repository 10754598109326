import { makeTableCellConfig } from './makeTableCellConfig';
export const defaultColumnConfig = [
    makeTableCellConfig('name', {
        active: true,
    }),
    makeTableCellConfig('updatedAt', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentContractOwner', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentStatus', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentEsigningStatus', {
        active: false,
    }),
    makeTableCellConfig('metadataDocumentDateSigned', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentCounterparty', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentParties', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentStartDate', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentNoticeDate', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentTerminationDate', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentAutomaticRenewal', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentCurrency', {
        active: true,
    }),
    makeTableCellConfig('metadataDocumentTotalContractValue', {
        active: true,
    }),
    makeTableCellConfig('createdByUser'),
    makeTableCellConfig('metadataDocumentDocumentType'),
    makeTableCellConfig('createdAt'),
    makeTableCellConfig('metadataDocumentMaterialAgreement'),
    makeTableCellConfig('metadataDocumentActive'),
    makeTableCellConfig('metadataDocumentIsSharedExternally'),
    makeTableCellConfig('metadataDocumentDeviateFromStandard'),
    makeTableCellConfig('metadataDocumentGoverningLaw'),
    makeTableCellConfig('metadataDocumentNotes'),
    makeTableCellConfig('metadataDocumentNoticePeriodMonths'),
    makeTableCellConfig('metadataDocumentOriginalTermMonths'),
    makeTableCellConfig('metadataDocumentRenewalTermMonths'),
    makeTableCellConfig('metadataDocumentTotalContractCost'),
    makeTableCellConfig('metadataDocumentProcessingOfPersonalData'),
    makeTableCellConfig('metadataDocumentOutsourcing'),
    makeTableCellConfig('metadataDocumentNoticePeriodDays'),
    makeTableCellConfig('metadataDocumentDocumentCategory'),
    makeTableCellConfig('metadataDocumentType'),
    makeTableCellConfig('metadataDocumentDiscount'),
    makeTableCellConfig('metadataDocumentFeeAdjustmentClause'),
    makeTableCellConfig('metadataDocumentIndemnity'),
    makeTableCellConfig('metadataDocumentLiabilityCap'),
    makeTableCellConfig('metadataDocumentLiabilityCapScope'),
    makeTableCellConfig('metadataDocumentLimitationOfLiability'),
    makeTableCellConfig('metadataDocumentPaymentTermsDaysFromInvoiceDate'),
    makeTableCellConfig('metadataDocumentPostTerminationObligations'),
    makeTableCellConfig('metadataDocumentProbationaryPeriod'),
    makeTableCellConfig('metadataDocumentProbationaryPeriodExpirationDate'),
    makeTableCellConfig('metadataDocumentTerminationForCause'),
    makeTableCellConfig('metadataDocumentTerminationForConvenience'),
    makeTableCellConfig('action', {
        active: true,
    }),
];
