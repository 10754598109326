import { t, plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { MetricCard } from './MetricCard';
import { MetricCardLoader } from './MetricCardLoader';
import { useDocumentsCreatedLastTwoMonthsMetrics } from './useDocumentsCreatedLastTwoMonthsMetrics';
export function DocumentsCreatedWidget() {
    const title = t({
        message: 'Created',
        comment: 'Title for the Documents created widget',
    });
    const errorMessage = t({
        message: 'Failed to load created documents',
        comment: 'Error message for the Documents created widget',
    });
    const tooltip = t({
        message: 'Documents created between now and one month back, compared to previous month',
        comment: 'Tooltip for the Documents created widget',
    });
    const { thisMonth, loading, error } = useDocumentsCreatedLastTwoMonthsMetrics();
    if (loading) {
        return <MetricCardLoader />;
    }
    if (error) {
        return (<Alert message={errorMessage} appearance="danger" icon={<Falcon icon="circle-xmark"/>}/>);
    }
    const suffix = t({
        message: plural(thisMonth, {
            one: 'Document',
            other: 'Documents',
        }),
        comment: 'Suffix for the Documents created widget',
    });
    return (<MetricCard title={title} value={thisMonth} suffix={suffix} icon={<Falcon icon="file"/>} tooltip={tooltip}/>);
}
