import { useLingui } from '@lingui/react';
import { getDocumentStatusTranslations } from 'app/domains/documents';
import { FileSystemDocumentStatus } from 'shared/domains/apollo/generated/types';
import { searchFilterCopy } from './searchFilterCopy';
import { MultiSelectFilter, useMultiselectFilter, } from '../MultiSelectFilter';
export function DocumentStatusFilter() {
    const lingui = useLingui();
    const items = [
        FileSystemDocumentStatus.Unknown,
        FileSystemDocumentStatus.DraftStage,
        FileSystemDocumentStatus.ReviewStagePending,
        FileSystemDocumentStatus.AgreedStageAgreedForm,
        FileSystemDocumentStatus.SigningStagePendingNotYetSigned,
        FileSystemDocumentStatus.SigningStagePendingPartiallySigned,
        FileSystemDocumentStatus.SigningStageSigned,
    ].map(status => ({
        id: status,
        title: lingui._(getDocumentStatusTranslations(status)),
    }));
    const filter = useMultiselectFilter({
        items: items || [],
        paramKey: 'documentStatus',
        name: searchFilterCopy().documentStatus,
    });
    return <MultiSelectFilter filter={filter}/>;
}
