import { Box, Typography, pxToRem } from '@pocketlaw/tetris';
import { Digit } from './Digit';
import { AnimatedList } from './styled';
export function Count(props) {
    const { entries, activeName, subtitle, totalCount } = props;
    const totalCountEntry = { name: 'totalCount', value: totalCount };
    const entriesWithTotalCount = (entries === null || entries === void 0 ? void 0 : entries.length) ? [totalCountEntry, ...entries] : [totalCountEntry];
    const activeEntry = activeName || 'totalCount';
    return (<Box flexDirection="column" width={pxToRem(200)} height="100%" justifyContent="center">
      <AnimatedList>
        {entriesWithTotalCount.map(entry => (<Digit active={entry.name === activeEntry} key={entry.name}>
            {entry.value || 0}
          </Digit>))}
      </AnimatedList>

      <Typography $appearance="100" $lineHeight="neutralized" $truncate>
        {subtitle}
      </Typography>
    </Box>);
}
