import { Fragment } from 'react';
import { t } from '@lingui/macro';
import { SearchAggregationTimeUnit } from 'shared/domains/apollo/generated/types';
import { Select, TextField } from 'shared/domains/forms';
export function RangeOffset(props) {
    const timeUnits = [
        {
            value: SearchAggregationTimeUnit.D,
            label: t({ message: 'Day', comment: 'Insights widget form: Day' }),
        },
        {
            value: SearchAggregationTimeUnit.W,
            label: t({ message: 'Week', comment: 'Insights widget form: Week' }),
        },
        {
            value: SearchAggregationTimeUnit.M,
            label: t({ message: 'Month', comment: 'Insights widget form: Month' }),
        },
        {
            value: SearchAggregationTimeUnit.Y,
            label: t({ message: 'Year', comment: 'Insights widget form: Year' }),
        },
    ];
    const { name } = props;
    return (<Fragment>
      <Select name={`${name}.roundingTimeUnit`} label={t({
            message: 'Rounding time unit',
            comment: 'Insights widget form: Rounding time unit',
        })}>
        {timeUnits.map(unit => (<option key={unit.value} value={unit.value}>
            {unit.label}
          </option>))}
      </Select>
      <Select name={`${name}.timeUnit`} label={t({ message: 'Time unit', comment: 'Insights widget form: Time unit' })}>
        {timeUnits.map(unit => (<option key={unit.value} value={unit.value}>
            {unit.label}
          </option>))}
      </Select>
      <TextField name={`${name}.value`} label={t({ message: 'Value', comment: 'Insights widget form: Value' })} type="number"/>
    </Fragment>);
}
