import { t } from '@lingui/macro';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { useMultiselectFilter, MultiSelectFilter } from '../../MultiSelectFilter';
import { searchFilterCopy } from '../searchFilterCopy';
export function DocumentEntityTypeFilter() {
    const filter = useMultiselectFilter({
        isActive: true,
        items: [
            {
                id: SearchEntityTypeEnum.DocumentComposed,
                title: t({
                    message: 'Document (generated)',
                    comment: 'Search entity type filter: Documents (generated)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentFreetext,
                title: t({
                    message: 'Document (freetext)',
                    comment: 'Search entity type filter: Document (freetext)',
                }),
            },
            {
                id: SearchEntityTypeEnum.DocumentUploaded,
                title: t({
                    message: 'Document (uploaded)',
                    comment: 'Search entity type filter: Document (uploaded)',
                }),
            },
        ],
        paramKey: 'type',
        name: searchFilterCopy().type,
    });
    return <MultiSelectFilter filter={filter}/>;
}
