import { Button, DialogFooter } from '@pocketlaw/tetris';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { Pagination } from 'shared/domains/common-ui';
import { usePickerContext } from '../usePickerContext';
export function PickerFooter(props) {
    const { submitLabel } = props;
    const { submit } = usePickerContext();
    const { loading, limit, offset, setOffset, count } = useFileBrowserContext();
    return (<DialogFooter description={<Pagination count={count} maxCount={10000} limit={limit} offset={offset} setOffset={setOffset} disabled={loading}/>}>
      <Button appearance="primary" disabled={loading} onClick={submit}>
        {submitLabel}
      </Button>
    </DialogFooter>);
}
