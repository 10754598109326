import { t, plural } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { MetricCard } from './MetricCard';
import { MetricCardLoader } from './MetricCardLoader';
import { useSharedDocumentsCount } from './useSharedDocumentsCount';
export function SharedDocumentsWidget() {
    const title = t({
        message: 'Shared',
        comment: 'Title for the Shared Documents widget',
    });
    const errorMessage = t({
        message: 'Failed to load shared documents',
        comment: 'Error message for the Shared Documents widget',
    });
    const { sharedDocumentsCount, loading, error } = useSharedDocumentsCount();
    const suffix = t({
        message: plural(sharedDocumentsCount, {
            one: 'Document',
            other: 'Documents',
        }),
        comment: 'Suffix for the Shared Documents widget',
    });
    if (loading) {
        return <MetricCardLoader />;
    }
    if (error) {
        return (<Alert message={errorMessage} appearance="danger" icon={<Falcon icon="circle-xmark"/>}/>);
    }
    return (<MetricCard title={title} value={sharedDocumentsCount} suffix={suffix} icon={<Falcon icon="share"/>} to="/documents/shared" tooltip={t({
            message: 'Documents that have been shared externally',
            comment: 'Tooltip for the Shared Documents widget',
        })}/>);
}
