import { t } from '@lingui/macro';
export function searchFilterCopy() {
    const copy = {
        type: t({ message: 'Content type', comment: 'Search: Entity type filter' }),
        onlyName: t({ message: 'Title only', comment: 'Search: Title only filter' }),
        templateCategory: t({ message: 'Template category', comment: 'Search: Category filter' }),
        user: t({ message: 'Users', comment: 'Search: User filter' }),
        createdAt: t({ message: 'Created at', comment: 'Search: Created at filter' }),
        updatedAt: t({ message: 'Updated at', comment: 'Search: Updated at filter' }),
        esigning: t({ message: 'E-signing', comment: 'Search: E-signing status filter' }),
        shared: t({
            message: 'Shared externally',
            comment: 'Search: External sharing status filter',
        }),
        materialAgreement: t({
            message: 'Material agreement',
            comment: 'Search: Material agreement filter',
        }),
        documentType: t({
            message: 'Document type',
            comment: 'Search: Document type filter',
        }),
        terminationDate: t({
            message: 'Termination date',
            comment: 'Search: Termination date filter',
        }),
        noticeDate: t({ message: 'Notice date', comment: 'Search: Notice date filter' }),
        contractOwner: t({ message: 'Contract owner', comment: 'Search: Contract owner filter' }),
        counterParty: t({ message: 'Counter party', comment: 'Search: Counter party filter' }),
        renewalTerm: t({ message: 'Renewal term', comment: 'Search: Renewal term filter' }),
        noticePeriod: t({ message: 'Notice period', comment: 'Search: Notice period filter' }),
        totalContractValue: t({
            message: 'Total contract value',
            comment: 'Search: Total contract value filter',
        }),
        totalContractCost: t({
            message: 'Total contract cost',
            comment: 'Search: Total contract cost filter',
        }),
        originalTerm: t({
            message: 'Original term',
            comment: 'Search: Original term filter',
        }),
        automaticRenewal: t({
            message: 'Automatic renewal',
            comment: 'Search: Automatic renewal filter',
        }),
        deviateFromStandard: t({
            message: 'Deviate from standard',
            comment: 'Search: Deviate from standard filter',
        }),
        active: t({
            message: 'Active',
            comment: 'Search: Active filter',
        }),
        agreementStartDate: t({
            message: 'Agreement start date',
            comment: 'Search: Agreement start date filter',
        }),
        dateSigned: t({
            message: 'Date signed',
            comment: 'Search: Agreement date signed filter',
        }),
        currency: t({
            message: 'Currency',
            comment: 'Search: Currency filter',
        }),
        governingLaw: t({
            message: 'Governing law',
            comment: 'Search: Governing law filter',
        }),
        processingOfPersonalData: t({
            message: 'Processing of personal data',
            comment: 'Search: Processing of personal data filter',
        }),
        outsourcing: t({
            message: 'Outsourcing',
            comment: 'Search: Outsourcing filter',
        }),
        documentCategory: t({
            message: 'Document category',
            comment: 'Search: Document category filter',
        }),
        noticePeriodDays: t({
            message: 'Notice period days',
            comment: 'Search: Notice period (days) filter',
        }),
        mpath: t({
            message: 'Mpath',
            comment: 'Search: Mpath filter',
        }),
        paymentTermsDaysFromInvoiceDate: t({
            message: 'Payment terms days',
            comment: 'Search: Payment terms (days) filter',
        }),
        probationaryPeriod: t({
            message: 'Probationary period',
            comment: 'Search: Probationary period filter',
        }),
        probationaryPeriodExpirationDate: t({
            message: 'Probationary period expiration date',
            comment: 'Search: Probationary period expiration date filter',
        }),
        discount: t({
            message: 'Discount',
            comment: 'Search: Discount filter',
        }),
        feeAdjustmentClause: t({
            message: 'Fee adjustment clause',
            comment: 'Search: Fee adjustment clause filter',
        }),
        postTerminationObligations: t({
            message: 'Post termination obligations',
            comment: 'Search: Post termination obligations filter',
        }),
        liabilityCap: t({
            message: 'Liability cap',
            comment: 'Search: Liability cap filter',
        }),
        limitationOfLiability: t({
            message: 'Limitation of liability',
            comment: 'Search: Limitation of liability filter',
        }),
        terminationForConvenience: t({
            message: 'Termination for convenience',
            comment: 'Search: Termination for convenience filter',
        }),
        indemnity: t({
            message: 'Indemnity',
            comment: 'Search: Indemnity filter',
        }),
        terminationForCause: t({
            message: 'Termination for cause',
            comment: 'Search: Termination for cause filter',
        }),
        documentStatus: t({
            message: 'Document status',
            comment: 'Search: Document status filter',
        }),
        liabilityCapScope: t({
            message: 'Liability cap scope',
            comment: 'Search: Liability cap scope',
        }),
        parties: t({
            message: 'Parties',
            comment: 'Search: Parties filter',
        }),
    };
    return copy;
}
