import { createContext, useState } from 'react';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
export const AttachmentsContext = createContext(null);
export { useAttachments } from '../useAttachments';
export function Provider(props) {
    const { children, disabled, attachments, onAddAttachment, onRemoveAttachment, onRenameAttachment, } = props;
    const { properties: providerProperties } = useEsigningProviderMetadata();
    const { maxNumberOfAttachments } = providerProperties;
    const [uploadingAttachment, setUploadingAttachment] = useState(false);
    const [error, setError] = useState();
    const [fileSizeTooBigWarning, setFileSizeTooBigWarning] = useState(false);
    const exceedsMaxNumberOfAttachments = maxNumberOfAttachments !== null && attachments.length > maxNumberOfAttachments;
    const setAttachmentsError = (newError) => setError(newError);
    const value = {
        error,
        disabled,
        attachments,
        uploadingAttachment,
        maxNumberOfAttachments,
        exceedsMaxNumberOfAttachments,
        fileSizeTooBigWarning,
        setFileSizeTooBigWarning,
        setAttachmentsError,
        setUploadingAttachment,
        addAttachment: onAddAttachment,
        removeAttachment: onRemoveAttachment,
        updateAttachmentName: onRenameAttachment,
    };
    return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
}
