import { useCallback } from 'react';
import { buildDateRangeFilter } from '../components/DateRangeFilter';
import { buildMultiSelectFilter } from '../components/MultiSelectFilter';
import { buildNumberRangeFilter } from '../components/NumberRangeFilter';
import { buildEsigningFilter } from '../components/SearchFilters/ESigningFilter';
import { buildMaterialAgreementFilter } from '../components/SearchFilters/MaterialAgreementFilter';
import { buildCategoryFilter } from '../components/SearchFilters/TemplateCategoryFilter';
import { useSearchContext } from '../components/SearchProvider';
import { buildSimpleTextFilter } from '../components/SimpleTextFilter';
import { buildBooleanFilter, buildSingleSelectFilter } from '../components/SingleSelectFilter';
export const useSearchArgs = () => {
    const { getNumberRangeFilterValue, getSimpleTextFilterValue, getActiveFilters, getMultiSelectFilterValue, getDateRangeFilterValue, getBooleanFilterValue, getSingleSelectFilterValue, } = useSearchContext();
    const toSearchParams = useCallback((where) => {
        const searchParams = new URLSearchParams();
        if (!where) {
            return searchParams;
        }
        Object.entries(where).forEach(([key, value]) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            switch (key) {
                case 'entityType':
                    {
                        const filter = value;
                        (_a = filter.in) === null || _a === void 0 ? void 0 : _a.forEach(entityType => {
                            searchParams.append('type', entityType);
                        });
                    }
                    break;
                case 'categoryId':
                    {
                        const filter = value;
                        (_b = filter.in) === null || _b === void 0 ? void 0 : _b.forEach(categoryId => {
                            searchParams.append('category', categoryId);
                        });
                    }
                    break;
                case 'createdByUserId':
                    {
                        const filter = value;
                        (_c = filter.in) === null || _c === void 0 ? void 0 : _c.forEach(userId => {
                            searchParams.append('user', userId);
                        });
                    }
                    break;
                case 'createdAt':
                    {
                        const { gte, lte } = value;
                        searchParams.set('createdAt', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'updatedAt':
                    {
                        const { gte, lte } = value;
                        searchParams.set('updatedAt', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentEsigningStatus':
                    {
                        const filter = value;
                        (_d = filter.in) === null || _d === void 0 ? void 0 : _d.forEach(esigningStatus => {
                            searchParams.append('esigning', esigningStatus.toLowerCase());
                        });
                    }
                    break;
                case 'metadataDocumentIsSharedExternally':
                    {
                        const filter = value;
                        searchParams.set('shared', ((_e = filter.eq) === null || _e === void 0 ? void 0 : _e.toString()) || '');
                    }
                    break;
                case 'metadataDocumentMaterialAgreement':
                    {
                        const filter = value;
                        if ((_f = filter.in) === null || _f === void 0 ? void 0 : _f.length) {
                            searchParams.set('materialAgreement', 'set');
                        }
                        if (filter.isNull === true) {
                            searchParams.set('materialAgreement', 'not-set');
                        }
                        if (filter.eq === true) {
                            searchParams.set('materialAgreement', 'yes');
                        }
                        if (filter.eq === false) {
                            searchParams.set('materialAgreement', 'no');
                        }
                    }
                    break;
                case 'metadataDocumentDocumentType':
                    {
                        const filter = value;
                        (_g = filter.in) === null || _g === void 0 ? void 0 : _g.forEach(documentType => {
                            searchParams.append('documentType', documentType);
                        });
                    }
                    break;
                case 'metadataDocumentTerminationDate':
                    {
                        const { gte, lte } = value;
                        searchParams.set('terminationDate', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentNoticeDate':
                    {
                        const { gte, lte } = value;
                        searchParams.set('noticeDate', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentContractOwner':
                    {
                        const filter = value;
                        (_h = filter.in) === null || _h === void 0 ? void 0 : _h.forEach(contractOwner => {
                            searchParams.append('contractOwner', contractOwner);
                        });
                    }
                    break;
                case 'metadataDocumentCounterparty':
                    {
                        const filter = value;
                        (_j = filter.in) === null || _j === void 0 ? void 0 : _j.forEach(counterParty => {
                            searchParams.append('counterParty', counterParty);
                        });
                    }
                    break;
                case 'metadataDocumentRenewalTermMonths':
                    {
                        const { gte, lte } = value;
                        searchParams.set('renewalTerm', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentNoticePeriodMonths':
                    {
                        const { gte, lte } = value;
                        searchParams.set('noticePeriod', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentTotalContractValue':
                    {
                        const { gte, lte } = value;
                        searchParams.set('totalContractValue', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentTotalContractCost':
                    {
                        const { gte, lte } = value;
                        searchParams.set('totalContractCost', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentOriginalTermMonths':
                    {
                        const { gte, lte } = value;
                        searchParams.set('originalTerm', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentAutomaticRenewal':
                    {
                        const filter = value;
                        searchParams.set('automaticRenewal', ((_k = filter.eq) === null || _k === void 0 ? void 0 : _k.toString()) || '');
                    }
                    break;
                case 'metadataDocumentActive':
                    {
                        const filter = value;
                        searchParams.set('active', ((_l = filter.eq) === null || _l === void 0 ? void 0 : _l.toString()) || '');
                    }
                    break;
                case 'metadataDocumentDeviateFromStandard':
                    {
                        const filter = value;
                        searchParams.set('deviateFromStandard', ((_m = filter.eq) === null || _m === void 0 ? void 0 : _m.toString()) || '');
                    }
                    break;
                case 'metadataDocumentStartDate':
                    {
                        const { gte, lte } = value;
                        searchParams.set('agreementStartDate', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentDateSigned':
                    {
                        const { gte, lte } = value;
                        searchParams.set('dateSigned', `${gte || ''},${lte || ''}`);
                    }
                    break;
                case 'metadataDocumentCurrency':
                    {
                        const filter = value;
                        searchParams.set('currency', filter.eq || '');
                    }
                    break;
                case 'metadataDocumentGoverningLaw':
                    {
                        const filter = value;
                        searchParams.set('governingLaw', filter.eq || '');
                    }
                    break;
                case 'metadataDocumentProcessingOfPersonalData':
                    {
                        const filter = value;
                        searchParams.set('processingOfPersonalData', filter.eq || '');
                    }
                    break;
                case 'metadataDocumentOutsourcing':
                    {
                        const filter = value;
                        searchParams.set('outsourcing', ((_o = filter.eq) === null || _o === void 0 ? void 0 : _o.toString()) || '');
                    }
                    break;
                case 'metadataDocumentStatus':
                    {
                        const filter = value;
                        searchParams.set('documentStatus', filter.eq || '');
                    }
                    break;
                case 'metadataDocumentLiabilityCapScope':
                    {
                        const filter = value;
                        (_p = filter.in) === null || _p === void 0 ? void 0 : _p.forEach(scope => {
                            searchParams.append('liabilityCapScope', scope);
                        });
                    }
                    break;
                case 'metadataDocumentParties':
                    {
                        const filter = value;
                        (_q = filter.hasSome) === null || _q === void 0 ? void 0 : _q.forEach(party => {
                            searchParams.append('parties', party);
                        });
                    }
                    break;
                default:
                    break;
            }
        });
        return searchParams;
    }, []);
    const getSearchWhereArgs = useCallback((defaults) => {
        const activeFilters = getActiveFilters();
        const whereArg = Object.assign({}, defaults);
        activeFilters.forEach(key => {
            switch (key) {
                case 'templateCategory':
                    whereArg.categoryId = buildCategoryFilter(getMultiSelectFilterValue('templateCategory'));
                    break;
                case 'user':
                    whereArg.createdByUserId = buildMultiSelectFilter(getMultiSelectFilterValue('user'));
                    break;
                case 'createdAt':
                    whereArg.createdAt = buildDateRangeFilter(getDateRangeFilterValue('createdAt'));
                    break;
                case 'updatedAt':
                    whereArg.updatedAt = buildDateRangeFilter(getDateRangeFilterValue('updatedAt'));
                    break;
                case 'esigning':
                    whereArg.metadataDocumentEsigningStatus = buildEsigningFilter(getMultiSelectFilterValue('esigning'));
                    break;
                case 'shared':
                    whereArg.metadataDocumentIsSharedExternally = buildBooleanFilter(getBooleanFilterValue('shared'));
                    break;
                case 'materialAgreement':
                    whereArg.metadataDocumentMaterialAgreement = buildMaterialAgreementFilter(getSingleSelectFilterValue('materialAgreement'));
                    break;
                case 'documentType':
                    whereArg.metadataDocumentDocumentType = buildMultiSelectFilter(getMultiSelectFilterValue('documentType'));
                    break;
                case 'terminationDate':
                    whereArg.metadataDocumentTerminationDate = buildDateRangeFilter(getDateRangeFilterValue('terminationDate'));
                    break;
                case 'noticeDate':
                    whereArg.metadataDocumentNoticeDate = buildDateRangeFilter(getDateRangeFilterValue('noticeDate'));
                    break;
                case 'contractOwner':
                    whereArg.metadataDocumentContractOwner = buildMultiSelectFilter(getMultiSelectFilterValue('contractOwner'));
                    break;
                case 'counterParty':
                    whereArg.metadataDocumentCounterparty = buildSimpleTextFilter(getSimpleTextFilterValue('counterParty'));
                    break;
                case 'renewalTerm':
                    whereArg.metadataDocumentRenewalTermMonths = buildNumberRangeFilter(getNumberRangeFilterValue('renewalTerm'));
                    break;
                case 'noticePeriod':
                    whereArg.metadataDocumentNoticePeriodMonths = buildNumberRangeFilter(getNumberRangeFilterValue('noticePeriod'));
                    break;
                case 'totalContractValue':
                    whereArg.metadataDocumentTotalContractValue = buildNumberRangeFilter(getNumberRangeFilterValue('totalContractValue'));
                    break;
                case 'totalContractCost':
                    whereArg.metadataDocumentTotalContractCost = buildNumberRangeFilter(getNumberRangeFilterValue('totalContractCost'));
                    break;
                case 'originalTerm':
                    whereArg.metadataDocumentOriginalTermMonths = buildNumberRangeFilter(getNumberRangeFilterValue('originalTerm'));
                    break;
                case 'automaticRenewal':
                    whereArg.metadataDocumentAutomaticRenewal = buildBooleanFilter(getBooleanFilterValue('automaticRenewal'));
                    break;
                case 'active':
                    whereArg.metadataDocumentActive = buildBooleanFilter(getBooleanFilterValue('active'));
                    break;
                case 'deviateFromStandard':
                    whereArg.metadataDocumentDeviateFromStandard = buildBooleanFilter(getBooleanFilterValue('deviateFromStandard'));
                    break;
                case 'agreementStartDate':
                    whereArg.metadataDocumentStartDate = buildDateRangeFilter(getDateRangeFilterValue('agreementStartDate'));
                    break;
                case 'dateSigned':
                    whereArg.metadataDocumentDateSigned = buildDateRangeFilter(getDateRangeFilterValue('dateSigned'));
                    break;
                case 'currency':
                    whereArg.metadataDocumentCurrency = buildSingleSelectFilter(getSingleSelectFilterValue('currency'));
                    break;
                case 'governingLaw':
                    whereArg.metadataDocumentGoverningLaw = buildSingleSelectFilter(getSingleSelectFilterValue('governingLaw'));
                    break;
                case 'processingOfPersonalData':
                    whereArg.metadataDocumentProcessingOfPersonalData = buildSingleSelectFilter(getSingleSelectFilterValue('processingOfPersonalData'));
                    break;
                case 'outsourcing':
                    whereArg.metadataDocumentOutsourcing = buildBooleanFilter(getBooleanFilterValue('outsourcing'));
                    break;
                case 'documentCategory':
                    whereArg.metadataDocumentDocumentCategory = buildMultiSelectFilter(getMultiSelectFilterValue('documentCategory'));
                    break;
                case 'noticePeriodDays':
                    whereArg.metadataDocumentNoticePeriodDays = buildNumberRangeFilter(getNumberRangeFilterValue('noticePeriodDays'));
                    break;
                case 'mpath':
                    whereArg.metadataEntryPath = {
                        startsWith: getSimpleTextFilterValue('mpath'),
                    };
                    break;
                case 'paymentTermsDaysFromInvoiceDate':
                    whereArg.metadataDocumentPaymentTermsDaysFromInvoiceDate = buildNumberRangeFilter(getNumberRangeFilterValue('paymentTermsDaysFromInvoiceDate'));
                    break;
                case 'probationaryPeriod':
                    whereArg.metadataDocumentProbationaryPeriod = buildBooleanFilter(getBooleanFilterValue('probationaryPeriod'));
                    break;
                case 'probationaryPeriodExpirationDate':
                    whereArg.metadataDocumentProbationaryPeriodExpirationDate = buildDateRangeFilter(getDateRangeFilterValue('probationaryPeriodExpirationDate'));
                    break;
                case 'discount':
                    whereArg.metadataDocumentDiscount = buildBooleanFilter(getBooleanFilterValue('discount'));
                    break;
                case 'feeAdjustmentClause':
                    whereArg.metadataDocumentFeeAdjustmentClause = buildBooleanFilter(getBooleanFilterValue('feeAdjustmentClause'));
                    break;
                case 'postTerminationObligations':
                    whereArg.metadataDocumentPostTerminationObligations = buildBooleanFilter(getBooleanFilterValue('postTerminationObligations'));
                    break;
                case 'liabilityCap':
                    whereArg.metadataDocumentLiabilityCap = buildNumberRangeFilter(getNumberRangeFilterValue('liabilityCap'));
                    break;
                case 'limitationOfLiability':
                    whereArg.metadataDocumentLimitationOfLiability = buildBooleanFilter(getBooleanFilterValue('limitationOfLiability'));
                    break;
                case 'terminationForConvenience':
                    whereArg.metadataDocumentTerminationForConvenience = buildBooleanFilter(getBooleanFilterValue('terminationForConvenience'));
                    break;
                case 'indemnity':
                    whereArg.metadataDocumentIndemnity = buildBooleanFilter(getBooleanFilterValue('indemnity'));
                    break;
                case 'terminationForCause':
                    whereArg.metadataDocumentTerminationForCause = buildBooleanFilter(getBooleanFilterValue('terminationForCause'));
                    break;
                case 'documentStatus':
                    whereArg.metadataDocumentStatus = buildMultiSelectFilter(getMultiSelectFilterValue('documentStatus'));
                    break;
                case 'liabilityCapScope':
                    whereArg.metadataDocumentLiabilityCapScope = buildMultiSelectFilter(getMultiSelectFilterValue('liabilityCapScope'));
                    break;
                case 'parties': {
                    const partiesValues = getMultiSelectFilterValue('parties');
                    whereArg.metadataDocumentParties = {
                        hasSome: partiesValues,
                    };
                    break;
                }
                default:
                    break;
            }
        });
        return whereArg;
    }, [
        getActiveFilters,
        getBooleanFilterValue,
        getDateRangeFilterValue,
        getMultiSelectFilterValue,
        getNumberRangeFilterValue,
        getSimpleTextFilterValue,
        getSingleSelectFilterValue,
    ]);
    return {
        getSearchWhereArgs,
        toSearchParams,
    };
};
