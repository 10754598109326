import { bucketTypeGuard } from '../utils';
export function hasBucketedBreakdown(data) {
    const isBucketAggregation = bucketTypeGuard(data);
    return isBucketAggregation && data.buckets.some(bucket => { var _a; return (_a = bucket.aggregations) === null || _a === void 0 ? void 0 : _a.length; });
}
export function getNextAggregation(aggregations) {
    const [aggregation] = aggregations || [];
    const { dateHistogramInput, dateRangeInput, termsInput } = aggregation || {};
    const { aggregations: inputAggregations } = dateHistogramInput || dateRangeInput || termsInput || {};
    return inputAggregations || aggregations || [];
}
