import { t } from '@lingui/macro';
import { Box, Chip, Tooltip, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function VisualizationMeta(props) {
    const { count, missing, sumOtherCount, totalHitsCount, diffValue } = props;
    const showCount = Boolean(count && count >= 0);
    const showDiffValue = typeof diffValue === 'number';
    const positiveDiff = showDiffValue && diffValue > 0;
    return (<Box gap="lg" alignItems="center" mb="lg" pt="lg">
      {showCount && (<Tooltip title={t({
                message: `Found ${count} contracts with data. There is a total of ${totalHitsCount} contracts found.`,
                comment: 'Insights: Total contracts found',
            })}>
          <Typography $fontSize="huge" $fontWeight="bold" $appearance="300" $lineHeight="neutralized">
            {count}
          </Typography>
        </Tooltip>)}
      <Box gap="md">
        {showDiffValue && (<Tooltip title={t({
                message: 'Difference in between two most recent intervals',
                comment: 'Insights: Difference tooltip for widget',
            })}>
            <Chip label={diffValue} appearance={positiveDiff ? 'success' : 'warning'} icon={positiveDiff ? <Falcon icon="arrow-up"/> : <Falcon icon="arrow-down"/>}/>
          </Tooltip>)}
        {Boolean(missing) && (<Tooltip title={t({
                message: `There is ${missing} contracts with no value set.`,
                comment: 'Insights: Missing count description',
            })}>
            <Chip appearance="secondary" label={t({ message: 'Missing', comment: 'Chart missing count label' })} secondary={missing} icon={<Falcon icon="tag"/>}/>
          </Tooltip>)}
        {Boolean(sumOtherCount) && (<Tooltip title={t({
                message: `There is currently ${sumOtherCount} entries excluded from the chart and not visible here. Increase the entries size configuration if you like to see them.`,
                comment: 'Insights: Terms aggregation configuration notice.',
            })}>
            <Chip appearance="secondary" label={t({ message: 'Excluded', comment: 'Excluded data chip label' })} icon={<Falcon icon="eye-slash"/>} secondary={sumOtherCount}/>
          </Tooltip>)}
      </Box>
    </Box>);
}
