import { Chip, ChipAction, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchableMenu, SearchableMenuSingleSelectList } from 'shared/domains/common-ui';
export function SingleSelectFilter(props) {
    var _a;
    const { filter } = props;
    if (!filter.isActive && !filter.isInDraft)
        return null;
    return (<SearchableMenu items={filter.items} keys={['title']} initiallyOpen={filter.isInDraft} renderTarget={({ setOpen, open }) => (<Chip label={filter.name} secondary={filter.value} onClick={() => setOpen(!open)} size="large" appearance={filter.value !== undefined ? 'info' : 'secondary'} action={filter.value !== undefined && (<ChipAction onClick={filter.clear} type="button">
                <Falcon icon="remove"/>
              </ChipAction>)}/>)}>
      <MenuBody>
        <SearchableMenuSingleSelectList selected={(_a = filter.currentItems) === null || _a === void 0 ? void 0 : _a.id} renderItem={({ title }) => title} onSelect={item => filter.select(item)}/>
      </MenuBody>
    </SearchableMenu>);
}
