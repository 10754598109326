import { getNextAggregation } from './TableWidget/utils';
import { fieldDisplayValue } from './fieldDisplayValue';
import { getSelectedField } from './getSelectedField';
import { bucketTypeGuard } from './utils';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../constants';
export function transformAggregation(dataPoint, aggregation) {
    const isBucketedResponse = bucketTypeGuard(dataPoint);
    if (!isBucketedResponse) {
        return {
            entries: [],
            keys: [],
            missing: undefined,
            count: 0,
            breakdown: false,
        };
    }
    const { buckets } = dataPoint;
    const primaryField = getSelectedField(aggregation);
    let breakdown = false;
    const transformedData = buckets === null || buckets === void 0 ? void 0 : buckets.reduce((acc, bucket) => {
        const { name, value, aggregations: subDataPoints, from, to } = bucket;
        const { keys = [], entries, missing } = acc;
        breakdown = Boolean(subDataPoints === null || subDataPoints === void 0 ? void 0 : subDataPoints.length);
        const isDateRangeBucket = Boolean(from || to);
        const newKeys = [...keys];
        const data = {};
        const formattedValue = value !== null && value !== void 0 ? value : 0;
        if (isDateRangeBucket) {
            const formattedFrom = fieldDisplayValue(primaryField, from);
            const formattedTo = fieldDisplayValue(primaryField, to);
            Object.assign(data, {
                name: `${formattedFrom} - ${formattedTo}`,
                total: formattedValue,
            });
        }
        else {
            const formattedPrimaryName = fieldDisplayValue(primaryField, name);
            Object.assign(data, {
                name: formattedPrimaryName,
                total: formattedValue,
            });
        }
        if (!breakdown) {
            newKeys.push('total');
        }
        if (subDataPoints === null || subDataPoints === void 0 ? void 0 : subDataPoints.length) {
            subDataPoints.forEach(subDataPoint => {
                if (!bucketTypeGuard(subDataPoint)) {
                    return;
                }
                const [breakdownAggregation] = getNextAggregation([aggregation]);
                const breakdownField = getSelectedField(breakdownAggregation);
                const { buckets: aggBucket } = subDataPoint;
                aggBucket.forEach(subAggregationBucket => {
                    const { name: bName, value: bValue } = subAggregationBucket;
                    const formattedBreakdownName = fieldDisplayValue(breakdownField, bName);
                    const formattedBreakdownValue = bValue !== null && bValue !== void 0 ? bValue : 0;
                    newKeys.push(formattedBreakdownName);
                    Object.assign(data, {
                        [formattedBreakdownName]: formattedBreakdownValue,
                    });
                });
            });
        }
        if (name === TERMS_AGGREGATION_MISSING_VALUE_NAME) {
            return {
                entries,
                keys,
                missing: value,
            };
        }
        return {
            entries: [...entries, data],
            keys: newKeys,
            missing,
        };
    }, {
        entries: [],
        keys: [],
        missing: undefined,
    });
    const { entries, keys, missing } = transformedData;
    const uniqueKeys = [...new Set(keys)];
    const count = entries.reduce((acc, curr) => acc + curr.total, 0);
    return {
        entries,
        missing,
        count,
        keys: uniqueKeys,
        breakdown,
    };
}
